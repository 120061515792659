<template>
    <MainPage :title="$t('lang.msg61')" v-on:callback="back" v-on:callmore="more">
        <div slot="content" >
            <div class="main-index main-bg-def" >
                <cube-scroll  ref="scroll" :data="results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore" >
                    <div class="RankTab">
                        <div @click="clickSumData">
                            <div><i class="fa-solid fa-square-caret-down"></i> {{$t('lang.msg217')}}</div>
                            <div>
                                {{SumData.Rebate1 + SumData.Rebate2 + SumData.Rebate3 + SumData.InviteRe | numFilter}} VND
                            </div>
                        </div>
                        <transition name="agentmenu">
                            <div v-show="ShowSumData">
                                <div class="RankBorder">{{$t('lang.msg214')}}</div>
                                <div>{{SumData.Rebate1+SumData.InviteRe | numFilter}} VND</div>
                                <div class="RankBorder">{{$t('lang.msg215')}}</div>
                                <div>{{SumData.Rebate2 | numFilter}} VND</div>
                                <div class="RankBorder">{{$t('lang.msg216')}}</div>
                                <div>{{SumData.Rebate3 | numFilter}} VND</div>
                            </div>
                        </transition>
                    </div>
                    <div class="RankTab" style="margin-top: -5px;">
                        <div @click="clickTeamData">
                            <div><i class="fa-solid fa-square-caret-down"></i> {{$t('lang.msg218')}}</div>
                            <div>
                                {{TeamData.Mc1+TeamData.Mc2+TeamData.Mc3}}
                            </div>
                        </div>
                        <transition name="agentmenu">
                            <div v-show="ShowTeamData">
                                <div @click="getTeamMember(1)">
                                    <div class="RankBorder">{{$t('lang.msg219')}}</div>
                                    <div>{{TeamData.Mc1}}</div>
                                </div>
                                <div v-show="STM1">
                                    <table class="table" >
                                        <tr v-for="(item, index) in TeamMember1" class="record-line" :key="index" style="height: 71px;">
                                            <td class="record-content text-left padding">
                                                <div>{{ item.Username }}</div>
                                                <div>{{ item.Coin | numFilter}}</div>
                                            </td>
                                            <td class="record-content text-right padding">
                                                <div>{{$t('lang.msg145')}} {{ getMomentFullDate(item.CreatedAt)}}</div>
                                                <div>{{$t('lang.msg146')}} {{ getMomentFullDate(item.LoginAt)}}</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div @click="getTeamMember(2)">
                                    <div class="RankBorder">{{$t('lang.msg220')}}</div>
                                    <div>{{TeamData.Mc2}}</div>
                                </div>
                                <div v-show="STM2">
                                    <table class="table" >
                                        <tr v-for="(item, index) in TeamMember2" class="record-line" :key="index" style="height: 71px;">
                                            <td class="record-content text-left padding">
                                                <div>{{ item.Username }}</div>
                                                <div>{{ item.Coin | numFilter}}</div>
                                            </td>
                                            <td class="record-content text-right padding">
                                                <div>{{$t('lang.msg145')}} {{ getMomentFullDate(item.CreatedAt)}}</div>
                                                <div>{{$t('lang.msg146')}} {{ getMomentFullDate(item.LoginAt)}}</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div @click="getTeamMember(3)">
                                    <div class="RankBorder">{{$t('lang.msg221')}}</div>
                                    <div>{{TeamData.Mc3}}</div>
                                </div>
                                <div v-show="STM3">
                                    <table class="table" >
                                        <tr v-for="(item, index) in TeamMember3" class="record-line" :key="index" style="height: 71px;">
                                            <td class="record-content text-left padding">
                                                <div>{{ item.Username }}</div>
                                                <div>{{ item.Coin | numFilter}}</div>
                                            </td>
                                            <td class="record-content text-right padding">
                                                <div>{{$t('lang.msg145')}} {{ getMomentFullDate(item.CreatedAt)}}</div>
                                                <div>{{$t('lang.msg146')}} {{ getMomentFullDate(item.LoginAt)}}</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </transition>
                    </div>                    
                  <cube-tab-bar v-model="selectedLabelDefault"
                                    show-slider
                                    @click="clickHandler"
                                    :data="tabs" class="mail-tab-bar" style="position: unset;" >
                    </cube-tab-bar>
                    <div v-show="ShowRank1" class="RankTable">
                        <table class="table" >
                            <tr v-for="(item, index) in ReportList1" class="record-line" :key="index" >
                                <td class="record-content text-left padding">
                                    <div>{{ item.SourceName }}</div>
                                    <div>{{ getMomentDate(item.CreatedAt)}} {{ getMomentTime(item.CreatedAt)}}</div>
                                </td>
                                <td class="record-content text-right padding">
                                    <div>{{ item.Coin | numFilter}}</div>
                                    <div>{{ $t(config.cash_type[item.Type]) }}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-show="ShowRank2" class="RankTable">
                        <table class="table" >
                            <tr v-for="(item, index) in ReportList2" class="record-line" :key="index" style="height: 71px;">
                                <td class="record-content text-left padding">
                                    <div>{{ item.SourceName }}</div>
                                    <div>{{ getMomentDate(item.CreatedAt)}} {{ getMomentTime(item.CreatedAt)}}</div>
                                </td>
                                <td class="record-content text-right padding">
                                    <div>{{ item.Coin | numFilter}}</div>
                                    <div>{{ $t(config.cash_type[item.Type]) }}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-show="ShowRank3" class="RankTable">
                        <table class="table" >
                            <tr v-for="(item, index) in ReportList3" class="record-line" :key="index" >
                                <td class="record-content text-left padding">
                                    <div>{{ item.SourceName }}</div>
                                    <div>{{ getMomentDate(item.CreatedAt)}} {{ getMomentTime(item.CreatedAt)}}</div>
                                </td>
                                <td class="record-content text-right padding">
                                    <div>{{ item.Coin | numFilter}}</div>
                                    <div>{{ $t(config.cash_type[item.Type]) }}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- <div style="height: 210px;">&nbsp; </div> -->
                </cube-scroll>
            </div>
            <transition name="filtermenu">
                <component :is="default_view" v-if="show_view" v-on:callback="search" v-on:showSearch="showSearch"></component>
            </transition>
            <AgentTabBar></AgentTabBar>
        </div>
    </MainPage>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import AgentTabBar from '@/components/agent/agent-tab-bar.vue'
import { apiAgentTransLog,apiTeamMember } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            show_view:false,
            filterData:{
                StartValue:moment().subtract(7,'d').format("YYYY-MM-DD"),
                EndValue:moment(new Date()).format("YYYY-MM-DD"),
            },
            STM1:false,
            STM2:false,
            STM3:false,
            TeamMember1:[],
            TeamMember2:[],
            TeamMember3:[],
            selectedLabelDefault:1,
            results:[],
            ReportList1:[],
            ReportList2:[],
            ReportList3:[],
            SumData:[],
            TeamData:[],
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: '',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
            ShowRank1 : true,
            ShowRank2 : false,
            ShowRank3 : false,
            ShowSumData : false,
            ShowTeamData : false,
        }
    },
    methods: {
        clickTeamData()
        {
          this.ShowTeamData = !this.ShowTeamData
          return
        },
        clickSumData()
        {
          this.ShowSumData = !this.ShowSumData
          return
        },
        clickHandler(current)
        {
            this.$refs.scroll.refresh();
            switch(current)
            {
                case 1:
                    this.ShowRank1=true;
                    this.ShowRank2=false;
                    this.ShowRank3=false;
                    break;
                case 2:
                    this.ShowRank1=false;
                    this.ShowRank2=true;
                    this.ShowRank3=false;
                    break;
                case 3:
                    this.ShowRank1=false;
                    this.ShowRank2=false;
                    this.ShowRank3=true;
                    break;
            }
        },
        search()
        {
            this.show_view=false
        },
        more()
        {
            this.show_view=true
            this.default_view=require('@/components/agent/team-search.vue').default;
        },
        showSearch(data)
        {
            this.show_view=false
            if(data!=null)
            {
                this.current_page=1;
                this.filterData=data;
                this.getLog(this.current_page);
            }
        },
        back()
        {
            this.$parent.back();
        },
        //apiTeamMember
        getTeamMember(Line)
        {
            switch(Line){
                case 1:
                    if(!this.STM1){
                        this.STM1 = true
                    }else{
                        this.STM1 = false
                        return
                    }
                break;
                case 2:
                    if(!this.STM2){
                        this.STM2 = true
                    }else{
                        this.STM2 = false
                        return
                    }
                break;
                case 3:
                    if(!this.STM3){
                        this.STM3 = true
                    }else{
                        this.STM3 = false
                        return
                    }
                break;
            }
            apiTeamMember({
                Line: Line,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        switch(Line){
                            case 1:
                                this.TeamMember1=response.data.data;
                            break;
                            case 2:
                                this.TeamMember2=response.data.data;
                            break;
                            case 3:
                                this.TeamMember3=response.data.data;
                            break;
                        }
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        getLog(page)
        {
            apiAgentTransLog({
                page: page,
                fromTime: this.restYMD(this.filterData.StartValue),
                toTime: this.restYMD(this.filterData.EndValue),
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.ReportList1.splice(0,this.ReportList1.length);
                        this.ReportList2.splice(0,this.ReportList2.length);
                        this.ReportList3.splice(0,this.ReportList3.length);
                        this.results.splice(0,this.results.length);
                        this.SumData = response.data.data.SumData
                        this.TeamData = response.data.data.TeamData

                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        // if(page==1){
                        // }
                        for(var n in response.data.data.ReportList)
                        {
                            switch (response.data.data.ReportList[n].Type)
                            {
                                case 7:
                                    this.ReportList1=this.ReportList1.concat(response.data.data.ReportList[n]);
                                break;
                                case 11:
                                    this.ReportList2=this.ReportList2.concat(response.data.data.ReportList[n]);
                                break;
                                case 12:
                                    this.ReportList3=this.ReportList3.concat(response.data.data.ReportList[n]);
                                break;
                                case 10:
                                    this.ReportList1=this.ReportList1.concat(response.data.data.ReportList[n]);
                                break;
                            }
                        }
                        this.results=this.results.concat(response.data.data.ReportList);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        // onItemClick(index)
        // {
        //     apiMemberDetial({
        //         id: this.results[index].ID,
        //         })
        //         .then(response => {
        //             if(!response.data.status)
        //             {
        //                 this.error_handler(response.data);
        //             }
        //             if(response.data.status)
        //             {
        //                 let content ="";
        //                 content +=this.$t('lang.msg184')+":"+response.data.data+"<br>";
        //                 content +=this.$t('lang.msg144')+":"+this.results[index].Used+"<br>";
        //                 content +=this.$t('lang.msg146')+":"+this.getMomentYDate(this.results[index].LoginAt)+"<br>";
        //                 content +=this.$t('lang.msg145')+":"+this.getMomentYDate(this.results[index].CreatedAt)+"<br>";
                        
        //                 this.dialog('alert',this.results[index].Username,content);
        //             }
        //             return;
        //         }).catch(error => {
        //             console.log(error);
        //             return;
        //         });
        // },
        logRefresh()
        {
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore()
        {
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.log.pullUpLoadNoMoreTxt
                    }
                },
            }
        },
        tabs()
        {
            let data= 
            [
                {
                    label: this.$t('lang.msg214'),
                    value:1,
                },
                {
                    label: this.$t('lang.msg215'),
                    value:2,
                },
                {
                    label: this.$t('lang.msg216'),
                    value:3,
                },
            ];
            return data;
        },
    },
    components: {
        MainPage,
        AgentTabBar
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.RankTable
    // position: fixed;
    // top: 0px;
    // right: 0px
    // left: 0px
    // bottom: 78px
</style>
