<template>
    <MainPage :title="$t('lang.msg61')" v-on:callback="back" v-on:callmore="more">
        <div slot="content" >
            <div class="main-index main-bg15">
                <cube-scroll  ref="scroll" :data="results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore">
                    <div class="record-time-zone">
                        <i class="fas fa-clock " ></i><div class="time-padding">{{getMomentYDate(filterData.StartValue)}}</div> ~ <div class="time-padding">{{getMomentYDate(filterData.EndValue)}}</div>
                    </div>                
                    <table class="table">
                        <tr v-for="(item, index) in results" class="record-line" :key="index" >
                            <td class="record-content text-left padding">{{ getMomentYDate(item.CreatedAt)}} </td>
                            <td class="record-content text-left padding">{{ $t('lang.msg159') }}</td>
                            <td class="record-content text-left padding">+{{ item.Rebate | numFilter }}</td>
                        </tr>
                    </table>
                    <div class="no-record" v-if="!results.length">
                        <img class="no-record-img" src="@/assets/images/user/norecord.png">
                        <div class="no-record-txt">{{$t('lang.msg35')}}</div>
                    </div>
                </cube-scroll>
            </div>
            <transition name="filtermenu">
                <component :is="default_view" v-if="show_view" v-on:callback="search" v-on:showSearch="showSearch"></component>
            </transition>
            <AgentTabBar></AgentTabBar>
        </div>
    </MainPage>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import AgentTabBar from '@/components/agent/agent-tab-bar.vue'
import { apiAgentRebate } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            show_view:false,
            filterData:{
                StartValue:moment().subtract(7,'d').format("DD-MM-YYYY"),
                EndValue:moment(new Date()).format("DD-MM-YYYY"),
                Username:'',
            },  
            results:[],
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: '',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
        }
    },
    methods: {
        search()
        {
            this.show_view=false
        },
        more()
        {
            this.show_view=true
            this.default_view=require('@/components/agent/rebate-search.vue').default;
        },
        showSearch(data)
        {
            this.show_view=false
            if(data!=null)
            {
                this.current_page=1;
                this.filterData=data;
                this.getLog(this.current_page);
            }
        },
        back()
        {
            this.$parent.back();
        },
        getLog(page)
        {
            apiAgentRebate({
                page: page,
                fromTime: this.restYMD(this.filterData.StartValue),
                toTime: this.restYMD(this.filterData.EndValue),
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.results.splice(0,this.results.length);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        this.results=this.results.concat(response.data.data);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        logRefresh()
        {
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore()
        {
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.log.pullUpLoadNoMoreTxt
                    }
                },
            }
        },
    },
    components: {
        MainPage,
        AgentTabBar
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
