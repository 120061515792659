<template>
  <MainPage :title="$t('lang.msg55')"  :nomore="true" v-on:callback="showSearch">
    <div slot="content">
        <div class="main-index main-bg2">
            <cube-scroll  ref="scroll">
            <div class="detail-head">
<!--                <div class="type">
                    <div class="dot" >{{ getFirstVendor($t('lang.msg124')) }}</div>
                    {{$t('lang.msg31')}}
                </div> -->
                <div class="amount">
                    {{ detail.Amount | numFilter}}
                </div>
                <div class="status" :class="{'bet-status-opened':detail.Status==1}">
                    {{ getRechargeStatus(detail) }}
                </div>
            </div>
            <div class="detail-body">
                    <div class="detail-item">
                        <div class="detail-title">{{$t('lang.msg125')}}</div><div class="detail-content">{{$t('lang.msg31')}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="detail-title">{{$t('lang.msg126')}}</div><div class="detail-content">{{ detail.RechargeModelName }}</div>
                    </div>
                    <div class="detail-item">
                        <div class="detail-title">{{$t('lang.msg127')}}</div><div class="detail-content">{{ detail.RechargeComName }}</div>
                    </div>
                    <div class="detail-item">
                        <div class="detail-title">{{$t('lang.msg128')}}</div><div class="detail-content">{{ getMomentFullDate(detail.CreatedAt) }}</div>
                    </div>
                    <div class="detail-item">
                        <div class="detail-title">{{$t('lang.msg129')}}</div><div class="detail-content">{{ getMomentFullDate(detail.UpdatedAt) }}</div>
                    </div>
                    <div class="detail-item">
                        <div class="detail-title">{{$t('lang.msg130')}}</div><div class="detail-content">{{ detail.RechargeID }}</div>
                    </div>
            </div>
            </cube-scroll>
        </div>
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6">
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { globalMixin } from '@/mixin.js';
  export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
      return {

      }
    },
    props: ['detail'],
    methods: {
        showSearch()
        {
          this.$emit('switchDetial');
        },
        getFirstVendor(vendor)
		{
			return vendor.substring(0,1);
		}
    },
    components: {
        MainPage,
    },

  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scpoed>
.main-index
    .detail-head
        margin: 10px 0;
        position: relative;
        padding: 10px;
        .type
            text-align: center;
            font-size: .4rem;
            line-height: 15px;
            padding: 10px 0;
            .dot
                border-radius: 50%;
                background-color: $dot-bg-color;
                display: inline-block;
                color: #fff;
                font-weight: 700;
                font-size: .8rem;
                padding: 5px 5px;
                width: 40px;          
                height: 40px;
                line-height: 40px;
        .amount
            text-align: center;
            font-size: .8rem;
            padding: 10px 0;
            font-weight: 700;
        .status
            text-align: center;
            font-size: .4rem;
            padding: 10px 0;
        .bet-status-opened
            color $font-green-color;        
    .detail-body
        // background: $index-bg-color;
        background: $record-item-bg-color;
        position: relative;
        .detail-item
            padding-left: 15px;
            padding-top: 15px;    
            font-size: .4rem;
            line-height: 14px;
            min-height: 30px;
            border-bottom: 1px $border-bottom-color solid;
            .detail-title
                float left
            .detail-content
                float right
                padding-right 15px;
</style>