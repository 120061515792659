<template>
    <MainPage :title="$t('lang.msg222')" v-on:callback="back" v-on:callmore="more">
        <div slot="content" >
            <div class="main-index main-bg16" >
                    <div class="RankTab">
                        <div @click="clickSumData">
                            <div><i class="fa-solid fa-square-caret-down"></i> {{$t('lang.msg217')}}</div>
                            <div>
                                {{SumData.FixedBonus + SumData.FreeBonus | numFilter}} VND
                            </div>
                        </div>
                        <transition name="agentmenu">
                            <div v-show="ShowSumData">
                                <div class="RankBorder">{{$t('lang.msg223')}}</div>
                                <div>{{SumData.FreeBonus | numFilter}} VND</div>
                                <div class="RankBorder">{{$t('lang.msg224')}}</div>
                                <div>{{SumData.FixedBonus | numFilter}} VND</div>
                            </div>
                        </transition>
                    </div>                 
                  <cube-tab-bar v-model="selectedLabelDefault"
                                    show-slider
                                    @click="clickHandler"
                                    :data="tabs" class="mail-tab-bar" style="position: unset;" >
                    </cube-tab-bar>
                    <cube-scroll  ref="scroll" :data="results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore" >
                    <div v-show="ShowRank1" class="RankTable">
                        <table class="table" >
                            <tr v-for="(item, index) in ReportList1" class="record-line" :key="index" >
                                <td class="record-content text-left padding">
                                    <div>{{ item.SourceName }}</div>
                                    <div>{{ getMomentDate(item.CreatedAt)}} {{ getMomentTime(item.CreatedAt)}}</div>
                                </td>
                                <td class="record-content text-right padding">
                                    <div>{{ item.Coin | numFilter}}</div>
                                    <div>{{ $t(config.cash_type[item.Type]) }}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-show="ShowRank2" class="RankTable">
                        <table class="table" >
                            <tr v-for="(item, index) in ReportList2" class="record-line" :key="index" style="height: 71px;">
                                <td class="record-content text-left padding">
                                    <div>{{ item.SourceName }}</div>
                                    <div>{{ getMomentDate(item.CreatedAt)}} {{ getMomentTime(item.CreatedAt)}}</div>
                                </td>
                                <td class="record-content text-right padding">
                                    <div>{{ item.Coin | numFilter}}</div>
                                    <div>{{ $t(config.cash_type[item.Type]) }}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style="height: 210px;">&nbsp; </div>
                </cube-scroll>
            </div>
            <transition name="filtermenu">
                <component :is="default_view" v-if="show_view" v-on:callback="search" v-on:showSearch="showSearch"></component>
            </transition>
        </div>
    </MainPage>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiBonusLog } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            show_view:false,
            filterData:{
                StartValue:moment().subtract(7,'d').format("DD-MM-YYYY"),
                EndValue:moment(new Date()).format("DD-MM-YYYY"),
            },
            selectedLabelDefault:1,
            results:[],
            ReportList1:[],
            ReportList2:[],
            SumData:[],
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: '',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
            ShowRank1 : true,
            ShowRank2 : false,
            ShowSumData : false,
        }
    },
    methods: {
        clickSumData()
        {
          this.ShowSumData = !this.ShowSumData
          return
        },
        clickHandler(current)
        {
            this.$refs.scroll.refresh();
            switch(current)
            {
                case 1:
                    this.ShowRank1=true;
                    this.ShowRank2=false;
                    this.ShowRank3=false;
                    break;
                case 2:
                    this.ShowRank1=false;
                    this.ShowRank2=true;
                    this.ShowRank3=false;
                    break;
            }
        },
        search()
        {
            this.show_view=false
        },
        more()
        {
            this.show_view=true
            this.default_view=require('@/components/agent/team-search.vue').default;
        },
        showSearch(data)
        {
            this.show_view=false
            if(data!=null)
            {
                this.current_page=1;
                this.filterData=data;
                this.getLog(this.current_page);
            }
        },
        back()
        {
            this.$emit('switchView');
        },
        getLog(page)
        {
            apiBonusLog({
                page: page,
                fromTime: this.restYMD(this.filterData.StartValue),
                toTime: this.restYMD(this.filterData.EndValue),
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.ReportList1.splice(0,this.ReportList1.length);
                        this.ReportList2.splice(0,this.ReportList2.length);
                        this.results.splice(0,this.results.length);
                        this.SumData = response.data.data.SumData
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        // if(page==1){
                        // }
                        for(var n in response.data.data.ReportList)
                        {
                            switch (response.data.data.ReportList[n].Type)
                            {
                                case 13:
                                    this.ReportList1=this.ReportList1.concat(response.data.data.ReportList[n]);
                                break;
                                case 14:
                                    this.ReportList2=this.ReportList2.concat(response.data.data.ReportList[n]);
                                break;
                            }
                        }
                        this.results=this.results.concat(response.data.data.ReportList);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        logRefresh()
        {
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore()
        {
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.log.pullUpLoadNoMoreTxt
                    }
                },
            }
        },
        tabs()
        {
            let data= 
            [
                {
                    label: this.$t('lang.msg223'),
                    value:1,
                },
                {
                    label: this.$t('lang.msg224'),
                    value:2,
                },
            ];
            return data;
        },
    },
    components: {
        MainPage,
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.RankTable
    // position: fixed;
    // top: 0px;
    // right: 0px
    // left: 0px
    // bottom: 78px
</style>
