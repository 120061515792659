<template>
  <MainPage :title="$t('lang.msg236')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg14">
            <form>
                <div>
                <cube-scroll ref="scroll" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh">
                    <div>
                        <div class="record-item"  >
                            <div class="col40 text-left">{{$t('lang.msg240')}}</div>
                            <div class="col60 text-left">
                                <cube-select
                                  v-model="form.from" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')" 
                                  :options="wallet_type" style="width: 100%;">
                                </cube-select>
                            </div>
                        </div>
                        <div class="record-item"  >
                            <div class="col40 text-left">{{$t('lang.msg241')}}</div>
                            <div class="col60 text-left">
                                <cube-select
                                  v-model="form.to" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')" 
                                  :options="wallet_type" style="width: 100%;">
                                </cube-select>
                            </div>
                        </div>
                        <div class="record-item" style="border-bottom:unset;padding: 10px 10px 0px 10px;margin-bottom:unset">
                            <div class="col40 text-left"></div>
                            <div class="col60" style="margin-left:5px">{{ShowCoNum}}</div>
                        </div>                          
                        <div class="record-item" >
                            <div class="col40 text-left">{{$t('lang.msg122')}}</div>
                            <div class="col60 text-left">
                                <cube-input v-model="form.amount"
                                    :placeholder="$t('lang.msg99')"
                                    type="tel"
                                    :clearable="{visible: true, blurHidden: true}"
                                    autocomplete="off"
                                    >
                                </cube-input>
                                <cube-validator v-model="check1" :model="form.amount" :rules="rules1" class="error-msg"></cube-validator>
                            </div>
                        </div>

                        <div class="padding">
                            <cube-button  @click="finish" class="reg-button blue-btn" :disabled="!check1 " :class="{'disabled-btn':!check1 }">{{$t('lang.msg115')}}</cube-button>
                        </div>
                    </div>
                </cube-scroll>
                </div>
            </form>
        </div> 

    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'    
import { apiTransWallet } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
import { validator } from '@/utils/validator.js'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            ShowCoNum:'',
            check1:false,
            check2:false,
            form:{
                amount:0, 
                from: 0,
                to: 0,
            },
            rules1:{
                type: 'number',
                required: true,
            },
            rules2:{
                type: 'string',
                required: true,
            },
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: '', //'Resfresh Success',
            },     
        }
    },  
    methods: {
        back()
        {
            this.$emit('switchView');
        },        
        switchView()
        {
            this.getCoin();
            this.default_view=null;
        }, 
        finish()
        {
            if(!validator.num_pos_int(this.form.amount))
            {
                this.toast(this.$t('lang.err4'));
                return;
            }
            if(this.form.amount < 1)
            {
                this.toast(this.$t('lang.err10'));
                return;
            }
            if(this.form.from == this.form.to)
            {
                this.toast(this.$t('lang.err11'));
                return;
            }
			var amount = parseFloat(this.user.coin);
			if(this.form.amount > amount) {
                this.toast(this.$t('lang.err8'));
				return;
			}
            this.toast(this.$t('lang.msg106'),null,0); // 防使用者連點
            //this.check3=false; // 鎖住下一步,防使用者連點
            apiTransWallet({
                    amount: this.form.amount,
                    from: this.form.from,
                    to: this.form.to,
                })
                .then(response => {
                    this.$createToast().hide();
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if (response.data.status) {
                        //this.nowStep++;
                        this.checkLogin();
                        this.dialog('alert',this.$t('lang.msg242'),this.$t('lang.msg242'));
                    }
                    return;
                })
                .catch(error => {
                    this.$createToast().hide();
                    console.log(error);
                });
        },
        getCoin()
        {
            this.checkLogin();
            if(this.$refs.scroll)
            {
                this.$refs.scroll.forceUpdate(false);
            }
        },
        logRefresh(){
            setTimeout(() => {
                this.getCoin();
            }, 1000);
        },       
    },
    watch:{
        'form.amount':function(val){
            const result = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.$nextTick(() => this.ShowCoNum = result);
        }
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        scrollLogOpts() 
        {
            return {
                pullUpLoad: false,
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.$t('lang.msg120'),
                },
                scrollbar: false,
            }
        },
        wallet_type(){
            var data = [];
            let _coin = ""
            for(var n in this.config.wallet_type)
            {
                if(n==2){
                    continue
                }
                switch(true)
                {
                    case n==0:
                        _coin = this.remarkCoin(this.user.Coin)
                    break;
                    case n==1:
                        _coin = this.remarkCoin(this.user.FreeCoin)
                    break;
                    case n==2:
                        _coin = this.remarkCoin(this.user.FixCoin)
                    break;
                }
                data.push({
                    value:n,
                    text:this.$t(this.config.wallet_type[n])+" $"+_coin
                });
            }
            return data;
        }  
    },
    components: {
      MainPage,
    },
    created() {
        this.getCoin();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .setup-space
        margin-top 20px;
        margin-bottom: 20px;
        font-size 0.9rem;
        line-height 20px;
    .record-item
        display flex;
        margin-bottom 1px;
        padding: 5px 10px;
        border-bottom: 1px solid;
        border-color $border-bottom-color
    .icon
        margin-left 10px;
    .record-info
        padding-bottom 20px;
        text-align center;
</style>
