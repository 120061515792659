<template>
  <MainPage :title="$t('lang.msg32')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <form>
                <div>
                <cube-scroll ref="scroll" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh">

                    <!-- <vue-step :now-step="nowStep" :step-list="stepList" :active-color="activeColor" class="setup-space"></vue-step> -->
                    <!-- <div class="record-info" v-if="nowStep==1"> -->
                    <div class="record-info" style="margin-top:15px">
                        {{$t('lang.msg108')}}
                    </div>
<!--                    <div class="record-info" style="margin-top:5px">
                        {{$t('lang.msg190')}}
                    </div> -->

                    <!-- <div v-if="nowStep==1"> -->
                    <div>
                        <div class="record-item" @click="onItemClick(1)" >
                            <div class="col50 text-left">{{$t('lang.msg109')}}</div>
                            <div class="col50 text-right"><span v-if="!safe.CoinPassword">{{$t('lang.msg73')}}</span><span v-else>{{$t('lang.msg110')}}</span><i class="icon cubeic-arrow"></i></div>
                        </div>
                        <div class="record-item" @click="onItemClick(2)">
                            <div class="col50 text-left">{{$t('lang.msg111')}}</div>
                            <div class="col50 text-right"><span v-if="safe.BankName==''">{{$t('lang.msg73')}}</span><span v-else>{{$t('lang.msg110')}}</span><i class="icon cubeic-arrow"></i></div>
                        </div>
<!--                        <div class="padding">
                           <cube-button  @click="next(2)" class="reg-button blue-btn" :disabled="!safe.CoinPassword || safe.BankName==''" :class="{'disabled-btn':!safe.coinPassword || safe.bank==''}">{{$t('lang.msg115')}}</cube-button>
                        </div> -->
                    </div>

                    <!-- <div v-if="nowStep==2"> -->
                    <div>
                        <div class="record-item"  >
                            <div class="col40 text-left">{{$t('lang.msg112')}}</div>
                            <div class="col60 text-right">{{user.Coin | numFilter}}</div>
                        </div>
                        <div class="record-item" >
                            <div class="col40 text-left">{{$t('lang.msg91')}}</div>
                            <div class="col60 text-right">{{safe.BankName}}</div>
                        </div>
                        <div class="record-item" v-if="safe.BankName=='USDT'">
                            1 USDT = {{safe.ucoinRate | numFilter}} VND
                        </div>                                              
                        <div class="record-item" style="border-bottom:unset;padding: 10px 10px 0px 10px;margin-bottom:unset">
                            <div class="col40 text-left"></div>
                            <div class="col60" style="margin-left:5px">{{ShowCoNum}}</div>
                        </div>                          
                        <div class="record-item" >
                            <div class="col40 text-left">{{$t('lang.msg113')}}</div>
                            <div class="col60 text-left">
                                <cube-input v-model="form.amount"
                                    :placeholder="$t('lang.msg99')"
                                    type="tel"
                                    :clearable="{visible: true, blurHidden: true}"
                                    autocomplete="off"
                                    >
                                </cube-input>
                                <cube-validator v-model="check1" :model="form.amount" :rules="rules1" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item" >
                            <div class="col40 text-left">{{$t('lang.msg69')}}</div>
                            <div class="col60 text-left">
                                <cube-input v-model="form.cashPassword"
                                    :placeholder="$t('lang.msg114')"
                                    type="password"
                                    autocomplete="false"
                                    >
                                </cube-input>
                                <cube-validator v-model="check2" :model="form.cashPassword" :rules="rules2" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="padding">
                            <cube-button  @click="finish" class="reg-button blue-btn" :disabled="!check1 || !check2 " :class="{'disabled-btn':!check1 || !check2 }">{{$t('lang.msg115')}}</cube-button>
                        </div>
<!--                        <div class="padding">
                            <cube-button  @click="next(1)" class="reg-button blue-btn" >{{$t('lang.msg116')}}</cube-button>
                        </div> -->
                    </div>

<!--                    <div v-if="nowStep==3">
                        <div class="record-finish blue-color" >
                                <div ><i class="record-finish-icon far fa-check-circle "></i></div>
                                <div> <span class="record-finish-text fw700">{{$t('lang.msg117')}}</span></div>
                                <div  style="color:#999">	{{$t('lang.msg118')}}</div>
                        </div>
                        <div class="padding">
                            <cube-button  @click="back" class="reg-button blue-btn" >{{$t('lang.msg119')}}</cube-button>
                        </div>
                    </div> -->

                </cube-scroll>
                </div>
            </form>
        </div> 

        <transition name="filtermenu">
            <keep-alive>
                <component :is="default_view" v-on:callback="switchView" :safe="safe"></component>
            </keep-alive>
        </transition>

    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'    
import vueStep from '@/components/vue-step.vue'
import CoinPassword from '@/components/user/coin-password.vue'
import Bank from '@/components/user/bank.vue'
import { apiSafe,apiCash } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
import { validator } from '@/utils/validator.js'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            ShowCoNum:'',
            check1:false,
            check2:false,
            //check3:true,
            default_view:null,
			min_amount: 1000,
			max_amount: 30000,
            safe:{
                coinPassword:true,
                email:'',
                phone:'',
            },
			form:{
				amount:'',
				cashPassword:'',
				limit: false,
            },
            rules1:{
                type: 'number',
                required: true,
                min:1000,
                max:30000
            },
            rules2:{
                type: 'string',
                required: true,
            },
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: '', //'Resfresh Success',
            },
            nowStep: 1,
            activeColor:"rgb(74, 144, 226)",         
        }
    },  
    methods: {
        back()
        {
            this.$emit('switchView');
        },        
        switchView()
        {
            this.getCoin();
            this.default_view=null;
        }, 
        next(step)
        {
            this.nowStep=step;
        },
        finish()
        {
            if(!validator.num_pos_int(this.form.amount))
            {
                this.toast(this.$t('lang.err4'));
                return;
            }
            if(this.form.amount < this.min_amount || this.form.amount > this.max_amount)
            {
                this.toast(this.$t('lang.err5') + this.min_amount + ' ~ ' + this.max_amount);
                return;
            }
			var amount = parseFloat(this.user.coin);
			if(this.form.amount > amount) {
                this.toast(this.$t('lang.err8'));
				return;
			}
			if (!this.form.cashPassword) {
                this.toast(this.$t('lang.msg114'));
				return;
            }
            this.toast(this.$t('lang.msg106'),null,0); // 防使用者連點
            //this.check3=false; // 鎖住下一步,防使用者連點
            apiCash({
                    amount: this.form.amount,
                    password: this.form.cashPassword
                })
                .then(response => {
                    this.$createToast().hide();
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if (response.data.status) {
                        //this.nowStep++;
                        this.checkLogin();
                        this.dialog('alert',this.$t('lang.msg117'),this.$t('lang.msg117'));
                    }
                    return;
                })
                .catch(error => {
                    this.$createToast().hide();
                    console.log(error);
                });
        },
        getCoin()
        {
            this.checkLogin();
            apiSafe({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data)
                        {
                            this.safe=response.data.data;
                            if(this.safe.BankName=="USDT"){
                                this.min_amount = this.safe.ucashMin
                                this.max_amount = this.safe.ucashMax
                                this.rules1.min = this.safe.ucashMin
                                this.rules1.max = this.safe.ucashMax
                            }else{
                                this.min_amount = this.safe.cashMin
                                this.max_amount = this.safe.cashMax
                                this.rules1.min = this.safe.cashMin
                                this.rules1.max = this.safe.cashMax
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            if(this.$refs.scroll)
            {
                this.$refs.scroll.forceUpdate(false);
            }
        },
        logRefresh(){
            setTimeout(() => {
                this.getCoin();
            }, 1000);
        },
        onItemClick(index)
        {
            switch(index)
            {
                case 1:
                    this.default_view=CoinPassword;
                    return;
                case 2:
                    this.default_view=Bank;
                    return;
            }
        },          
    },
    watch:{
        'form.amount':function(val){
            const result = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.$nextTick(() => this.ShowCoNum = result);
        }
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        scrollLogOpts() 
        {
            return {
                pullUpLoad: false,
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.$t('lang.msg120'),
                },
                scrollbar: false,
            }
        },
        stepList()
        {
            return [
                this.$t('lang.msg121'),
                this.$t('lang.msg122'),
                this.$t('lang.msg123'),
            ]
        }
            
    },
    components: {
      MainPage,
      vueStep
    },
    created() {
        this.getCoin();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .setup-space
        margin-top 20px;
        margin-bottom: 20px;
        font-size 0.9rem;
        line-height 20px;
    .record-item
        display flex;
        margin-bottom 1px;
        padding: 5px 10px;
        border-bottom: 1px solid;
        border-color $border-bottom-color
    .icon
        margin-left 10px;
    .record-info
        padding-bottom 20px;
        text-align center;
</style>
