<template>
  <MainPage :title="$t('lang.msg59')" v-on:callback="back" :nomore="true">
    <div slot="content" >
<!--        <div class="main-index">
            <cube-tab-bar v-model="selectedLabelDefault"
                            @click="clickHandler"
                            :data="tabs">
            </cube-tab-bar>
        </div> -->
        <transition name="filtermenu">
                <keep-alive>
                    <Team v-if="showTeam"  ></Team>
                </keep-alive>
        </transition>
        <transition name="filtermenu">
                <keep-alive>
                    <Qr v-if="showQr"  ></Qr>
                </keep-alive>
        </transition>
        <transition name="filtermenu">
                <keep-alive>
                    <Rebate v-if="showRebate"  ></Rebate>
                </keep-alive>
        </transition>

    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import Team from '@/views/agent/team.vue'
import Qr from '@/views/agent/qr.vue'
import Rebate from '@/views/agent/rebate.vue'
import { globalMixin } from '@/mixin.js';
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            selectedLabelDefault:1,
            showTeam:true,
            showQr:false,
            showRebate:false,
        }
    },
    methods: {
        back()
        {
            this.$emit('switchView');
        },
        agentPage(current)
        {
            this.selectedLabelDefault=current;
            switch(current)
            {
                case 1:
                    this.showTeam=true;
                    this.showQr=false;
                    this.showRebate=false;
                    break;
                case 2:
                    this.showTeam=false;
                    this.showQr=true;
                    this.showRebate=false;
                    break;
                case 3:
                    this.showTeam=false;
                    this.showQr=false;
                    this.showRebate=true;
                    break;
            }
        },
    },
    computed: {
    },
    components: {
      MainPage,
      Team,
      Qr,
      Rebate,
    },
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
