<template>
    <MainPage :title="$t('lang.msg95')" v-on:callback="back" :nomore="true">
        <div slot="content" >
            <div class="main-index user-center main-bg2" >
                <cube-scroll ref="scroll" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh">
                    <!-- <div class="header-bg"></div> -->
                    <div class="user-content">
                        <div class="user-top-box ">
                            <div class="row money">
                                <div class="wallet-box" style="text-align: left;padding-left: 15px;">
<!--                                    <div class="col-auto text-center" @click="CallView('recharge')" >
                                        <i class="icon fas fa-donate recharge" style="display: block;"></i>{{$t('lang.msg31')}}
                                    </div>
                                    <div class="col-auto text-center" @click="CallView('withdraw')" >
                                        <i class="icon fas fa-coins withdraw" style="display: block;"></i>{{$t('lang.msg32')}}
                                    </div> -->
                                   <div class=" fw700"><span><i class="fa-solid fa-user user-icon" ></i></span> :  {{ user.Username}} </div>
                                   <div class=" fw700">{{$t('lang.msg266')}} : {{getClass(user)}}</div>
                                </div>
                                <div class="wallet-total-box">
                                    <div class="user-point fw700">
                                       <div >
                                            {{$t('lang.msg237')}}
                                        </div>
                                        <div>
                                            {{user.Coin | numFilter}}
                                        </div>
<!--                                       <div style="text-align: center;">
                                           <div style="background: #2691c3;width: 110px;border-radius: 5px;color: #fff;margin: auto;padding: 3px;" @click="singed()" v-if="user.Signed == 0">{{$t('lang.msg243')}}</div>
                                           <div style="background: #2691c3;width: 110px;border-radius: 5px;color: #fff;margin: auto;padding: 3px;"  v-if="user.Signed == 1">{{$t('lang.msg247')+" "+user.CountSigned+$t('lang.msg167')}}</div>
                                        </div> -->
                                
<!--                                       <div class="cash-box">
                                            <div class="col-auto text-center" @click="CallView('recharge')" style="width: 50%;">
                                                <i class="icon fas fa-donate recharge"></i>{{$t('lang.msg31')}}
                                            </div>
                                            <div class="col-auto text-center" @click="CallView('withdraw')" style="width: 50%;">
                                                <i class="icon fas fa-coins withdraw"></i>{{$t('lang.msg32')}}
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                            
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="CallView('recharge')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg31')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="CallView('message')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg59')}}</span> 
                                        <span v-if="checkLetter > 0" style="color: #f84747;"> NEW</span></div>
                                </div>
                            </div>
<!--                            <div class="divider"></div>
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="CallView('agent')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg61')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="CallView('message')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg59')}}</span> 
                                        <span v-if="checkLetter > 0" style="color: #f84747;"> NEW</span></div>
                                </div>
                            </div> -->
                           <div class="divider"></div>
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="CallView('translog')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg51')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="CallView('rechargelog')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg55')}}</span></div>
                                </div>
                            </div>
<!--                           <div class="divider"></div>
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="CallView('translog')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg51')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="CallView('bidlog')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg52')}}</span></div>
                                </div>
                            </div>
                            <div class="divider" ></div>
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="CallView('rechargelog')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg55')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="CallView('withdrawlog')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg54')}}</span></div>
                                </div>
                            </div> -->
                            <div class="divider" ></div>
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="CallView('safe')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg56')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="CallView('promotion')" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg58')}}</span></div>
                                </div>
                            </div>
                            <div class="divider" ></div>
                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-left col50" @click="service()" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg163')}}</span></div>
                                </div>
                                <div class="col-auto text-left col50 row-left-line" @click="logout()" style="display: flex;">
                                    <div><i class="icon fa-solid fa-square-caret-right"></i></div>
                                    <div style="margin-top: 5px;"><span>{{$t('lang.msg60')}}</span></div>
                                </div>
                            </div>
                            <div class="divider" ></div>


                                                        
<!--                            <div class="row" style="padding-top: 10px">
                                <div class="col-auto text-center" @click="CallView('agent')">
                                    <i class="icon fas fa-users agent"></i>{{$t('lang.msg61')}}
                                </div>
                                <div class="col-auto text-center" @click="CallView('translog')">
                                    <i class="icon fas fa-coins withdraw"></i>{{$t('lang.msg51')}}
                                </div>
                                <div class="col-auto text-center" @click="CallView('bidlog')">
                                    <i class="icon fas fa-donate recharge"></i>{{$t('lang.msg52')}}
                                </div>
                            </div> -->
<!--                            <div class="row" >
                               <div class="col-auto text-center" @click="CallView('prodlog')">
                                    <i class="icon fas fa-clipboard-list coinlog"></i>{{$t('lang.msg53')}}
                                </div>
                                <div class="col-auto text-center" @click="CallView('rechargelog')">
                                    <i class="icon fas fa-chess ebetlog"></i>{{$t('lang.msg55')}}
                                </div>
                                <div class="col-auto text-center" @click="CallView('withdrawlog')">
                                    <i class="icon fas fa-file-alt betlog"></i>{{$t('lang.msg54')}}
                                </div>
                                <div class="col-auto text-center" @click="CallView('safe')">
                                    <span class="icon fas fa-cog safe"></span>{{$t('lang.msg56')}}
                                </div>
                            </div> -->

<!--                            <div class="row" >
                                <div class="col-auto text-center" @click="CallView('promotion')">
                                    <span class="icon fas fa-cog safe" ></span>{{$t('lang.msg58')}}
                                </div>
                                <div class="col-auto text-center" @click="CallView('message')">
                                    <span class="icon fas mail " :class="{'fa-envelope-open-text':checkLetter,'fa-envelope':!checkLetter}"></span>
                                    <span :class="{'box':checkLetter}">{{$t('lang.msg59')}}</span>
                                </div>
                                <div class="col-auto text-center" @click="logout">
                                    <span class="icon fas fa-power-off logout"></span>{{$t('lang.msg60')}}
                                </div>
                            </div> -->
<!--                            <div class="row" >
                                <div class="col-auto text-center" @click="logout">
                                    <span class="icon fas fa-power-off logout"></span>{{$t('lang.msg60')}}
                                </div>
                                <div class="col-auto text-center" >
                                </div>
                                <div class="col-auto text-center" >
                                </div>
                            </div> -->
                        </div>

                    </div>
                </cube-scroll>
            </div>
            <!-- <tab-bar></tab-bar> -->
            <transition name="filtermenu">
            <component :is="default_view" v-if="show_view"  v-on:switchView="CallView2"></component>
            </transition>
            <div v-show="showDetail" >
                <div class="Sign-layout">
                    <div style="padding: 15px;font-size: 16px;    color: #fff;
    background: #f12222;
    text-align: center;">
                        {{$t('lang.msg244')}}
                    </div>
                    <div style="margin-top: 10px; padding: 0px 15px;">
                        <div>
                            <img :src="require('@/assets/images/320.jpg')" style="width: 100%;">
                        </div>
                        <div>{{$t('lang.msg247')+" "+user.CountSigned+$t('lang.msg167')}}</div>
                        <div style="margin-top: 10px;">{{$t('lang.msg248')}} : {{SignPay | numFilter}}</div>
                    </div>
                    <div style="    width: 60%;
    margin: auto;
    margin-top: 20px;"><cube-button  @click="closeClick()" class="reg-button green-btn" >{{$t('lang.msg24')}}</cube-button></div>
                </div>
                <div class="keyboard_block" ></div>
            </div>
        </div>
    </MainPage>
</template>
<script>
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar.vue'
// import TabBar from '@/components/tab-bar.vue'
import { mapGetters } from 'vuex'
import { globalMixin } from '@/mixin.js'
import { apiCheckLetter,apiLogout,apiSigned } from "@/api.js";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            SignPay:0,
            showDetail:0,
            checkLetter:false,
            default_view:null,
            show_view:false,
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: '', //'Resfresh Success',
            },            
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            config: 'config',
        }),
        username(){
            return this.user.username;
        },
        scrollLogOpts() {
            return {
                pullUpLoad: false,
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                scrollbar: false,
            }
        },
    },
    components: {
        MainPage,
        // TabBar,
    },    
    methods: {
        closeClick(){
            this.showDetail=0;
        },        
        back()
        {
            this.$router.back()
        },
        singed(){
            this.toast('Loading...',null,0,'loading');
            apiSigned({
                })
                .then(response => {
                    this.$createToast().hide();
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                        this.checkLogin();
                    }
                    if(response.data.status)
                    {
                        //this.toast(this.$t('lang.msg244'));
                        this.SignPay = response.data.data.SignPay
                        //this.SignPay = 55555
                        this.showDetail=1;
                        this.checkLogin();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        CallView(path)
        {
            switch(path)
            {
                case "bonuslog":
                    this.default_view=require('@/views/user/bonuslog.vue').default;
                    break;
                case "translog":
                    this.default_view=require('@/views/user/translog.vue').default;
                    break;
                case "bidlog":
                    this.default_view=require('@/views/user/bidlog.vue').default;
                    break;
                case "prodlog":
                    this.default_view=require('@/views/user/prodlog.vue').default;
                    break;
                case "safe":
                    this.default_view=require('@/views/user/safe.vue').default;
                    break;
                case "recharge":
                    this.default_view=require('@/views/user/recharge.vue').default;
                    break;
                case "withdraw":
                    this.default_view=require('@/views/user/withdraw.vue').default;
                    break;
                case "rechargelog":
                    this.default_view=require('@/views/user/recharge-log.vue').default;
                    break;
                case "withdrawlog":
                    this.default_view=require('@/views/user/withdraw-log.vue').default;
                    break;
                case "promotion":
                    this.default_view=require('@/views/index/promotion.vue').default;
                    break;
                case "message":
                    this.default_view=require('@/views/user/message.vue').default;
                    break;
                case "agent":
                    this.default_view=require('@/views/agent/index.vue').default;
                    break;
                case "transWallet":
                    this.default_view=require('@/views/user/transwallet.vue').default;
                    break;
            }
            this.show_view=true;
        },
        CallView2()
        {
            this.show_view=false;
        },
        check_letter()
        {
            apiCheckLetter({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.checkLetter=response.data.data;
                    }
                    this.$refs.scroll.forceUpdate();
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        logout()
        {
            apiLogout({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.toast(
                            this.$t('lang.msg139'),
                            {
                                func: () => {
                                    this.$store.dispatch('clearUserInfo');
                                    this.$router.push("/");
                                    //this.$router.push("/login");
                                }
                            });
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        logRefresh(){
            this.checkLogin();
            this.check_letter();
        },        
    },
    created() {
        //this.check_letter();
        if(this.user.Username==''  || !this.user.Username){
            this.checkLogin();
        }
        this.check_letter();
    }
}
</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    // top: 40px;
    // bottom: 0px;
    // background-image: url("assets/images/index-bg.jpg")
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    .item-line
        justify-content: space-between;
        line-height: 48px;
        padding: 0px 10px;
    .row
        display: flex;
        .text-center
            text-align center;
    font-size .5rem;
    line-height: 1.2rem;
    text-align center
.user-center
  .user-content 
    margin 40px 20px 20px 20px
    color $font-white-color
    position relative
    .user-logo 
      width 85px
      height 85px
      background-size cover
      left 0
      right 0
      margin auto
      background-repeat no-repeat
      background-image url("./assets/images/user/logo.png")
    .user-class 
      left 0
      right 0
      margin auto
      position absolute
      z-index 2
      width 60px
      background linear-gradient(140deg,#4c0,#23550a)
      color #fff
      text-align center
      border-radius 2px
      top 30px
    .user-name 
      top -68px
      left 0
      right 0
      margin auto
      position absolute
      z-index 2
      text-align center
      font-size 17px
      color #eee
    .user-top-box 
      position relative
      background: $index-bg-color
      //border-radius 8px
      //padding 10px
      .wallet-box 
        width 50%
        padding 0px
      .wallet-total-box 
        width 50%
        // border-left 1px solid $border-white-color
        display flex;
        .user-point
            flex auto;
            .cash-box
                display inline-flex
                width 100%
        .user-more
            font-size: 1.5rem;
            line-height: 38px;
            color #ccc;
    .user-bottom-box 
      position relative
      background #fff
      border-radius 8px
    .divider 
      height 1px
      background-color $border-white-color
    .row-left-line
      border-left 1px solid $border-white-color
      padding-left 5px
    .row 
      &.money 
        padding 20px 0px 15px
        background-color: #21212194;
        color $main-block-white-font-color
        font-size: 16px;
        line-height: 30px;
    .col-auto 
        font-size 15px
      .icon 
        margin 10px 10px 2px 10px
        font-size 25px
      .icon-color
        color: #c5a848;
    .agent
        color #ee6e6e;
    .withdraw
        color $main-block-white-font-color;
    .recharge
        color $main-block-white-font-color;
    .coinlog
        color #44c26f;
    .betlog
        color #bca6e1;
    .mail
        color #ee6e6e;
    .withdrawLog
        color #44c26f;
    .rechargeLog
        color #61b6e5;
    .safe
        color #ee6e6e;
    .help
        color #61b6e5;
    .promotion
        color #44c26f;
    .logout
        color #ee6e6e;
    .ebetlog
        color #61b6e5;
    .user-icon
        color #f38181;
    .logout-icon
        color #eb3e3e
.user-center .user-content .row .col-auto:nth-last-child(3),.user-center .user-content  .row .col-auto:nth-last-child(3)~.col-auto 
  width calc((100%)/ 3)
.box{ 
    color:#e1e40d; 
    animation: changeshadow 2s  ease-in  infinite ;
    /* 其它浏览器兼容性前缀 */
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
}  
@keyframes changeshadow {  
    0%{ text-shadow: 0 0 4px #e40d2d}  
    50%{ text-shadow: 0 0 40px #e40d2d}  
    100%{ text-shadow: 0 0 4px #e40d2d}  
}
/* 添加兼容性前缀 */
@-webkit-keyframes changeshadow {
    0%{ text-shadow: 0 0 4px #e40d2d}  
        50%{ text-shadow: 0 0 40px #e40d2d}  
        100%{ text-shadow: 0 0 4px #e40d2d}  
}
@-moz-keyframes changeshadow {
    0%{ text-shadow: 0 0 4px #e40d2d}  
        50%{ text-shadow: 0 0 40px #e40d2d}  
        100%{ text-shadow: 0 0 4px #e40d2d}  
}
@-ms-keyframes changeshadow {
    0%{ text-shadow: 0 0 4px #e40d2d}  
        50%{ text-shadow: 0 0 40px #e40d2d}  
        100%{ text-shadow: 0 0 4px #e40d2d}  
}
@-o-keyframes changeshadow {
    0%{ text-shadow: 0 0 4px #e40d2d}  
        50%{ text-shadow: 0 0 40px #e40d2d}  
        100%{ text-shadow: 0 0 4px #e40d2d}  
}
</style>
