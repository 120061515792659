<template>
  <MainPage type="form-custom" :title="$t('lang.msg38')" v-on:callback="back" :nomore="true">
    <div slot="content">
      <cube-form :model="model" >
        <cube-form-group>
            <cube-form-item >
                <div style="display: flex;">
                    <div class="cube-form-label"><span>{{$t('lang.msg50')}}</span></div>
                    <div class="item-note" >
                        <cube-button  class="quick-btn" @click="quickButtonDate(0);quickBtn = 1" :class="{'active':quickBtn == 1}"> <span>{{$t('lang.msg39')}}</span></cube-button>
                        <cube-button  class="quick-btn" @click="quickButtonDate(2);quickBtn = 2" :class="{'active':quickBtn == 2}"> <span>{{$t('lang.msg40')}}</span></cube-button>
                        <cube-button  class="quick-btn" @click="quickButtonDate(6);quickBtn = 3" :class="{'active':quickBtn == 3}"> <span>{{$t('lang.msg41')}}</span></cube-button>
                    </div>
                </div>
            </cube-form-item>
            <cube-form-item :field="fields[0]" >
                <div style="display: flex;">
                    <div class="cube-form-label"><span>{{$t('lang.msg47')}}</span></div>
                    <cube-button class="date-btn" @click="showDatePickerStart">{{model.StartValue}}</cube-button>
                </div>
            </cube-form-item>
            <cube-form-item :field="fields[1]">
                <div style="display: flex;">
                    <div class="cube-form-label"><span>{{$t('lang.msg48')}}</span></div>
                    <cube-button class="date-btn" @click="showDatePickerEnd">{{model.EndValue}}</cube-button>
                </div>
            </cube-form-item>
            <cube-form-item :field="fields[2]" >
                <div style="display: flex;">
                    <div class="cube-form-label"><span>{{$t('lang.msg18')}}</span></div>
                    <cube-select
                      v-model="model.StatusValue" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')" 
                      :options="status_type" style="width: 100%;">
                    </cube-select>
                </div>
            </cube-form-item>
        </cube-form-group>
        <cube-form-group class="padding">
          <cube-button @click="submitHandler" class="search-btn">{{$t('lang.msg45')}}</cube-button>
          <cube-button @click="restHandler" class="rest-btn">{{$t('lang.msg46')}}</cube-button>
        </cube-form-group>
      </cube-form>
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6">
  import { Locale } from 'cube-ui'
  import moment from 'moment'
  import MainPage from '@/components/nav-bar-reback.vue'
  export default {
    mixins: [Locale.localeMixin],
    data() {
      return {
        quickBtn:0,
        model: {
            StatusValue: 4,
            StartValue: moment(new Date()).format("YYYY-MM-DD"),
            EndValue: moment(new Date()).format("YYYY-MM-DD"),
        },
        fields: [
            {
                modelKey: 'StartValue',
                rules: {
                  type:'date'
                }
            },
            {
                modelKey: 'EndValue',
                rules: {
                  type:'date'
                }
            },
            {
                modelKey: 'StatusValue',
                rules: {
                  type:'number'
                }
            }
        ]
      }
    },
    methods: {
        showDatePickerStart() {
          if (!this.datePickerStart) {
            this.datePickerStart = this.$createDatePicker({
              title: this.$t('lang.msg42'),
              cancelTxt: this.$t('lang.msg43'),
              confirmTxt: this.$t('lang.msg44'),
              min: this.limit_start_date,
              max: new Date(),
              value: new Date(),
              onSelect: this.selectStart,
            })
          }
          this.datePickerStart.show()
        },
        selectStart(date){
            this.model.StartValue = moment(date).format("YYYY-MM-DD")
        },
        showDatePickerEnd() {
          if (!this.datePickerEnd) {
            this.datePickerEnd = this.$createDatePicker({
              title: this.$t('lang.msg42'),
              cancelTxt: this.$t('lang.msg43'),
              confirmTxt: this.$t('lang.msg44'),
              min: this.limit_start_date,
              max: new Date(),
              value: new Date(),
              onSelect: this.selectEnd,
            })
          }
          this.datePickerEnd.show()
        },
        selectEnd(date){
            this.model.EndValue = moment(date).format("YYYY-MM-DD")
        },
      back()
      {
        this.$emit('callback');
      },
      submitHandler() {
        this.$emit('showSearch',0,this.model);
      },
      restHandler(){
        this.model.StartValue = moment(new Date()).format("YYYY-MM-DD");
        this.model.EndValue = moment(new Date()).format("YYYY-MM-DD");
        this.model.StatusValue=0;
      },
      quickButtonDate(day){
        this.model.StartValue = moment().subtract(day,'d').format('YYYY-MM-DD');
        this.model.EndValue = moment(new Date()).format("YYYY-MM-DD");
      },
    },
    components: {
        MainPage,
        //DatePicker,
    },
    computed:
    {
        limit_start_date(){
            var now = new Date((new Date()).getTime() - (90 * 86400 * 1000));
            var yyyy=moment(now).format("YYYY");
            var mm=moment(now).format("M");
            var dd=moment(now).format("D");
            return [yyyy,mm,dd];
        },
        status_type(){
            return [
                {value:4,text:this.$t('lang.msg34')},
                {value:0,text:this.$t('lang.msg20')},
                {value:1,text:this.$t('lang.msg21')},
                {value:2,text:this.$t('lang.msg22')},
              ];
        },
        label1()
        {
            return this.$t('lang.msg47')
        }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.cube-form-group
    .date-btn
        background-color unset
        color unset
        text-align left
    .item-note
        display inline-flex
    .cube-select
        font-size 15px
        text-align left
        padding 0px 15px
	.search-btn
		margin-bottom: 10px;
		border-radius: 5px;
	.rest-btn
		border-radius: 5px;
	.padding
		padding: 10px;

</style>