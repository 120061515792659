<template>
  <MainPage :title="$t('lang.msg72')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <form>
                <div class="record-body">
                <cube-scroll  ref="scroll" >
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg94')}}</div>
                                <div class="col60 " v-if="safe.Accountname==''" >
                                    <cube-input v-model="form.username"
                                        :placeholder="$t('lang.msg94')"
                                        type="text"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                                <div class="col60 " v-else >
                                    <cube-input v-model="safe.Accountname"
                                        placeholder=""
                                        type="text"
                                        class="disabled-color"
                                        autocomplete="false"
                                        :disabled="true"
                                        >
                                    </cube-input>
                                </div>                                
                            </div>
                            <div>
                                <cube-validator v-model="check1" :model="form.username" :rules="rules1" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left" v-if="form.bank != 90">{{$t('lang.msg92')}}</div>
                                <div class="col40 text-left"  v-if="form.bank == 90">{{$t('lang.msg185')}}</div>
                                <div class="col60 " v-if="safe.Account=='' && form.bank != 90">
                                    <cube-input v-model="form.account"
                                        :placeholder="$t('lang.msg93')"
                                        type="text"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                                <div class="col60 " v-if="safe.Account=='' && form.bank == 90">
                                    <cube-input v-model="form.account"
                                        :placeholder="$t('lang.msg185')"
                                        type="text"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                                <div class="col60 " v-if="safe.Account!=''">
                                    <cube-input v-model="safe.Account"
                                        placeholder=""
                                        type="text"
                                        class="disabled-color"
                                        autocomplete="false"
                                        :disabled="true"
                                        >
                                    </cube-input>
                                </div>                                
                            </div>
                            <div>
                                <cube-validator v-model="check2" :model="form.account" :rules="rules2" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg91')}}</div>
                                <div class="col60 " v-if="safe.BankID==0">
                                    <cube-select
                                    v-model="form.bank" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')" 
                                    :options="bank_array">
                                    </cube-select>
                                </div>
                                <div class="col60 " v-else>
                                    <cube-select
                                    v-model="safe.BankID" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')" 
                                    :options="bank_array"
                                    :disabled="true"
                                    >
                                    </cube-select>
                                </div>                                
                            </div>
                            <div>
                                <cube-validator v-model="check3" :model="form.bank" :rules="rules3" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item"  v-if="form.bank == 106">
                            <div class="record-top">
                                <div class="col40 text-left"  >{{$t('lang.msg91')}}</div>
                                <div class="col60 " v-if="safe.Account==''">
                                    <cube-input v-model="form.BankName"
                                        :placeholder="$t('lang.msg233')"
                                        type="text"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
<!--                                <div class="col60 " v-if="safe.Account!=''">
                                    <cube-input v-model="safe.BankName"
                                        placeholder=""
                                        type="text"
                                        class="disabled-color"
                                        autocomplete="false"
                                        :disabled="true"
                                        >
                                    </cube-input>
                                </div> -->
                            </div>
                        </div>
                        <div class="record-item"  v-if="safe.BankID == 106">
                            <div class="record-top">
                               <div class="col40 text-left"  >{{$t('lang.msg91')}}</div>
                               <div class="col60 " v-if="safe.Account!=''">
                                    <cube-input v-model="safe.BankName"
                                        placeholder=""
                                        type="text"
                                        class="disabled-color"
                                        autocomplete="false"
                                        :disabled="true"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                        </div>
                        <div class="record-item"  v-if="safe.Account==''">
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg69')}}</div>
                                <div class="col60 ">
                                    <cube-input v-model="form.coinpassword"
                                        :placeholder="$t('lang.msg77')"
                                        type="password"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                            <div>
                                <cube-validator v-model="check4" :model="form.coinpassword" :rules="rulespwd" class="error-msg"></cube-validator>
                            </div>
                        </div>
                    <div class="padding">
                        <cube-button v-if="safe.Account==''"  @click="up" class="reg-button blue-btn" :disabled=" !check1 || !check2 || !check3 || !check4" :class="{'disabled-btn':!check1 || !check2 || !check3 || !check4}">{{$t('lang.msg24')}}</cube-button>
                        <cube-button v-else :disabled="true" class="reg-button blue-btn disabled-btn" >{{$t('lang.msg24')}}</cube-button>
                    </div>
                    <div class="record-info">
                        {{$t('lang.msg85')}}
                    </div>                    
                    <div class="record-info">
                        {{$t('lang.msg269')}}
                    </div>                    
                </cube-scroll>
                </div>
            </form>
        </div> 
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiBank,apiSafeUp } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
import { validator } from '@/utils/validator.js'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            check1:false,
            check2:false,
            check3:false,
            check4:false,
            bank_list:null,
            form:{
                username:'',
                account:'',
                bank:0,
                coinpassword:'',
                BankName:'',
            },
            rules1:{
                type: 'string',
                required: true,
            },
            rules2:{
                type: 'string',
                required: true,
            },
            rules3:{
                type: 'number',
                required: true,
            },
            rulespwd:{
                type: 'string',
                required: true,
                pattern: validator.password,
                min:6,
            },            
        }
    },
    props: ['safe'],
    methods: {
        back()
        {
          this.$emit('callback',0);
        },   
        up()
        {
            if(this.safe.Account != ''){
                this.toast(this.$t('lang.msg90'));
                return;
            }
            if(this.form.bank == 106 && this.form.BankName==''){
                this.toast(this.$t('lang.msg233'));
                return;
            }
            apiSafeUp({
                type:5,
                username:this.form.username,
                account:this.form.account,
                bank:this.form.bank,
                BankName:this.form.BankName,
                coinpassword:this.form.coinpassword,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.toast(this.$t('lang.msg78'));
                        this.$emit('callback',0);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getBank()
        {
            apiBank({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.toast(response.data.error);
                        return;
                    }
                    if(response.data.status)
                    {
                        this.bank_list=response.data.data;
                        return;
                    }
                }).catch(error => {
                    console.log(error);
                    return;
                });
        }
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        bank_array(){
            var data=[];
            var lt=this.bank_list;
            // if(safe.bank_id!='')
            // {
            //     data.push({value:lt[k].id,text:lt[k].name});
            //     return 
            // }
            for(var k in lt)
            {
                data.push({value:lt[k].ID,text:lt[k].Name});
            }
            return data;
        },        
    },
    components: {
        MainPage
    },
    created() {
        this.getBank();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-item
        margin-bottom 1px;
        padding: 5px 15px;
        display block
        .record-top
            display flex;
        .error-msg
            text-align center;
            padding-top 5px;
    .icon
        margin-left 10px;
    .padding
        padding: 20px 10px;
    .reg-button
        margin-top 10px;
    .record-info
        padding-top 15px;
        text-align center;
        font-size 15px;
</style>
