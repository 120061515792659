import { render, staticRenderFns } from "./transwallet.vue?vue&type=template&id=2c0e9f3b&scoped=true&"
import script from "./transwallet.vue?vue&type=script&lang=js&"
export * from "./transwallet.vue?vue&type=script&lang=js&"
import style0 from "./transwallet.vue?vue&type=style&index=0&id=2c0e9f3b&prod&lang=stylus&rel=stylesheet%2Fstylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0e9f3b",
  null
  
)

export default component.exports