<template>
  <MainPage :title="$t('lang.msg55')"  v-on:callmore="more" v-on:callback="back">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <cube-scroll  ref="scroll" :data="results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore">
                <div class="record-time-zone">
                    <!-- <i class="fas fa-clock " ></i><div class="time-padding">{{getMomentYDate(filterData.StartValue)}}</div> ~ <div class="time-padding">{{getMomentYDate(filterData.EndValue)}}</div> -->
                    <i class="fas fa-clock " ></i><div class="time-padding">{{filterData.StartValue}}</div> ~ <div class="time-padding">{{filterData.EndValue}}</div>
                </div>
				<div v-for="(item, index) in results" class="record-item" :key="index"  @click="onItemClick(index)">
                    <div class="record-top">
                        <div class="col50">
                            <div>
                                {{item.RechargeModelName}}
                            </div>
                            <div>
                                {{ getRechargeStatus(item) }}
                            </div>
                            <div>
                                {{item.RechargeComName}}
                            </div>
                        </div>
                        <div class="col50">
                            <div class="red-color">
                                +{{ item.Amount |numFilter}}
                            </div>
                            <div>
                                {{ getMomentDate(item.CreatedAt)}} {{ getMomentTime(item.CreatedAt)}}
                            </div>
                        </div>
                    </div>
				</div>
                <div class="no-record" v-if="!results.length">
                    <img class="no-record-img" src="@/assets/images/user/norecord.png">
                    <div class="no-record-txt">{{$t('lang.msg35')}}</div>
                </div>
            </cube-scroll>
        </div>   
           
        <transition name="filtermenu">
            <component :is="default_view" v-if="show_view" v-on:callback="search" v-on:showSearch="showSearch"></component>
        </transition>
       <transition name="filtermenu">
            <keep-alive>
                <component :is="default_view" v-if="showDetail" :detail="checkDetail" v-on:switchDetial="switchDetial"></component>
            </keep-alive>
        </transition>

    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
//import RechargeDetail from '@/components/coin/recharge-detail.vue';
import moment from 'moment'
//import WithdrawSearch from '@/components/coin/withdraw-search.vue';
import { apiRechargeLog } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            show_view:false,
            filterData:{
                //StartValue:moment(new Date()).format("YYYY-MM-DD"),
                StartValue:moment().subtract(7,'d').format("YYYY-MM-DD"),
                EndValue:moment(new Date()).format("YYYY-MM-DD"),
            },
            showDetail:0,
            checkDetail:null,
            results:[],
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: '',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
        }
    },
    methods: {
        search()
        {
            this.show_view=false
        },
        more()
        {
            this.show_view=true
            this.default_view=require('@/components/coin/withdraw-search.vue').default;
        },
        back()
        {
            this.$emit('switchView');
        },        
        switchDetial()
        {
            this.showDetail=0;
        },
        showSearch(data)
        {
            this.show_view=false
            if(data!=null)
            {
                this.current_page=1;
                this.filterData=data;
                this.getLog(this.current_page);
            }
            //console.log(this.filterData);
        },
        getLog(page)
        {
            apiRechargeLog({
                page: page,
                fromTime: this.restYMD(this.filterData.StartValue),
                toTime: this.restYMD(this.filterData.EndValue),         
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.results.splice(0,this.results.length);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        this.results=this.results.concat(response.data.data);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        onItemClick(index)
        {
            this.checkDetail=this.results[index];
            this.default_view=require('@/components/coin/recharge-detail.vue').default;
            this.showDetail=1;
        },
        logRefresh(){
            if(this.filterData)
            {
                this.current_page=1;
                setTimeout(() => {
                    this.getLog(this.current_page);
                }, 1000);
            }else
            {
                this.$refs.scroll.forceUpdate();
            }
        },
        logLoadMore(){
            this.current_page++;
            if(this.filterData)
            {
                setTimeout(() => {
                    this.getLog(this.current_page);
                }, 1000);
            }else
            {
                this.$refs.scroll.forceUpdate();
            }
        },        
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.log.pullUpLoadNoMoreTxt
                    }
                },
                scrollbar: true
            }
        },        
    },
    components: {
      MainPage,
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .col40
        line-height 30px;
    .col60
        line-height 30px;
    .record-bot
        display flex;
        padding-bottom: 5px;
    .record-top
        display flex;
        padding-top: 10px;
        width: 100%;
        .dot
            border-radius: 50%;
            background-color: $dot-bg-color;
            display: inline-block;
            color: #fff;
            font-weight: 700;
            font-size: 20px;
            padding: 5px 5px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align center;
    .record-item
        margin: 0px 15px;
        padding: 0px 10px;
        border-bottom: 1px $border-bottom-color solid;
        background-color: $prod-black-color;
</style>
