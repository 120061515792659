<template>
  <MainPage :title="$t('lang.msg31')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
           <cube-tab-bar v-model="selectedLabelDefault"
                            show-slider
                            @click="clickHandler"
                            :data="tabs" class="mail-tab-bar" v-if="showTab">
            </cube-tab-bar>
            <form>
                <div class="record-body">
                <cube-scroll ref="scroll" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh">
                    <div v-if="nowStep==1">
                        <div v-if="showPay1">
                            <div class="record-item" >
                                <div class="col45 text-left">{{$t('lang.msg97')}}</div>
                                <div class="col55">
                                    <cube-select
                                        v-model="form.bank" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')"
                                        :options="bank_array"
                                        @change="change"
                                    >
                                    </cube-select>
                                </div>
                            </div>
                            <transition name="filtermenu">
                                <div v-if="form.bank">
                                    <div v-if="form.qrcode!=''">
                                        <div class="record-item" >
                                            <div class="record-qrcode">
                                                <vue-qr :text="form.qrcode" :size="200"></vue-qr>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="record-item" >
                                            <div class="col40 text-left">
                                                {{$t('lang.msg94')}}
                                            </div>
                                            <div class="col45 text-left">
                                                {{form.username}}
                                            </div>
                                            <div class="col15 text-right">
                                                <div class="copy-btn" @click="copy(form.username)">
                                                    {{$t('lang.msg98')}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="record-item" v-if="form.username != 'USDT'">
                                            <div class="col40 text-left">
                                                {{$t('lang.msg92')}}
                                            </div>
                                            <div class="col45 text-left">
                                                {{form.userbankid}}
                                            </div>
                                            <div class="col15 text-right">
                                                <div class="copy-btn" @click="copy(form.userbankid)">
                                                    {{$t('lang.msg98')}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="record-item" v-else>
                                            <div class="col40 text-left">
                                                {{$t('lang.msg185')}}
                                            </div>
                                            <div class="col45 text-left" style="overflow: hidden;white-space: nowrap; text-overflow: ellipsis;">
                                                {{form.userbankid}}
                                            </div>
                                            <div class="col15 text-right">
                                                <div class="copy-btn" @click="copy(form.userbankid)">
                                                    {{$t('lang.msg98')}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="record-item" v-if="form.username != 'USDT'">
                                            <div class="col40 text-left">{{$t('lang.msg105')}}</div>
                                            <div class="col45 text-left">{{form.memo}}</div>
                                            <div class="col15 text-right">
                                                <div class="copy-btn" @click="copy(form.memo)">
                                                    {{$t('lang.msg98')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="record-item" style="border-bottom:unset;padding: 10px 10px 0px 10px;">
                                        <div class="col25 text-left"></div>
                                        <div class="col75" style="margin-left:5px">{{ShowCoNum}}</div>
                                    </div>                                    
                                    <div class="record-item">
                                        <div class="col25 text-left">{{$t('lang.msg16')}}</div>
                                        <div class="col75">
                                            <cube-input v-model="form.amount"
                                                :placeholder="$t('lang.msg99')"
                                                type="tel"
                                                autocomplete="off"
                                                >
                                            </cube-input>
                                        </div>
                                    </div>
                                    <div class="record-error">
                                        <div class="col25 text-left"></div>
                                        <div class="col75 text-left">
                                            <cube-validator v-model="check1" :model="form.amount" :rules="rules1" class="error-msg"></cube-validator>
                                        </div>
                                    </div>
<!--                                    <div class="record-info" >
                                        <div class="record-info-content">
                                            {{form.remark}}
                                        </div>
                                    </div> -->
                                    <div class="record-item" v-if="form.username == 'USDT'">
                                        1 USDT = {{this.form.rate | numFilter}} VND
                                    </div>                                        
                                    <div class="record-item" >
                                        <cube-upload
                                          action="/api/comm/Upload?type=recharge"
                                          :simultaneous-uploads="1"
                                          :max="1"
                                          @files-added="filesAdded"
                                          @file-success="uploaded"
                                          @file-error="uploaderr"
                                           />                                    
                                    </div>  
<!--                                    <div >
                                        <div style="text-align: center;">only jpg,png. limit 10M</div>
                                    </div> -->
                                    <div >
                                        <div style="text-align: center;margin-top:10px">{{$t('lang.msg268')}} limit 10M</div>
                                    </div>
                                </div>
                            </transition>

                            <div class="padding">
                                <cube-button  @click="finish()" class="reg-button blue-btn" :disabled="form.bank=='' || !check1" :class="{'disabled-btn':form.bank=='' || !check1}">{{$t('lang.msg115')}}</cube-button>
                            </div>
                        </div>

                        <div v-if="showPay2">
                            <div class="record-item" >
                                <div class="col45 text-left">{{$t('lang.msg97')}}</div>
                                <div class="col55">
                                    <cube-select
                                        v-model="form.bank" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')"
                                        :options="third_array"
                                    >
                                    </cube-select>
                                </div>
                            </div>
                            <transition name="filtermenu">
                                <div v-if="form.bank">
                                    <div v-if="form.bank != 'USDT'">
                                        <div class="record-item" style="border-bottom:unset;padding: 10px 10px 0px 10px;">
                                            <div class="col45 text-left"></div>
                                            <div class="col55" style="margin-left:5px">{{ShowCoNum}}</div>
                                        </div>     
                                        <div class="record-item">
                                            <div class="col45 text-left">{{$t('lang.msg16')}}</div>
                                            <div class="col55">
                                                <cube-input v-model="form.amount"
                                                    :placeholder="$t('lang.msg99')"
                                                    type="tel"
                                                    autocomplete="off"
                                                    >
                                                </cube-input>
                                            </div>
                                        </div>
                                        <div class="record-error">
                                            <div class="col45 text-left"></div>
                                            <div class="col55 text-left">
                                                <cube-validator v-model="check1" :model="form.amount" :rules="rules1" class="error-msg"></cube-validator>
                                            </div>
                                        </div>
                                        <div class="record-item" >
                                            1 USDT = {{this.thirdRate | numFilter}} 
                                        </div>                                    
    <!--                                    <div class="record-item" >
                                            <cube-upload
                                              action="/api/comm/Upload?type=recharge"
                                              :simultaneous-uploads="1"
                                              @files-added="filesAdded"
                                              @file-success="uploaded"
                                              @file-error="uploaderr"
                                               />                                    
                                        </div>    -->  
                                    </div>
                                    <div v-if="form.bank == 'USDT'">
                                        <div class="record-item" >
                                            1 USDT = {{this.thirdRate | numFilter}} 
                                        </div>                                    
                                        <div class="record-item" >
                                            下面為您的貨幣地址:
                                        </div>                                    
                                        <div class="record-item" >
                                            {{this.Wallet}}
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            <div class="padding">
                                <cube-button v-if="form.bank != 'USDT'"  @click="third_finish()" class="reg-button blue-btn" :disabled="form.bank=='' || !check1" :class="{'disabled-btn':form.bank=='' || !check1}">{{$t('lang.msg115')}}</cube-button>
                                <cube-button v-if="form.bank == 'USDT'" class="copy-btn" @click="copy(Wallet)">複製貨幣地址</cube-button>
                            </div>
                        </div>

                        <div class="record-hint text-left">
                            <h3>{{$t('recharge.msg1')}}</h3>
                            <p>{{$t('recharge.msg2')}}</p>
                            <ol>
                                <!-- <li>{{$t('recharge.msg3')}}</li> -->
                                <li>{{$t('recharge.msg4')}}</li>
                                <li>使用USDT,請匯款到指定貨幣地址</li>
                            </ol>
                        </div>
                    </div>
                    <transition name="filtermenu">
                        <div v-if="nowStep==2">
                            <div class="record-finish blue-color" >
                                    <div ><i class="record-finish-icon far fa-check-circle "></i></div>
                                    <div> <span class="record-finish-text fw700">{{$t('lang.msg100')}}</span></div>
                                    <div  class="record-finish-commet" >{{$t('lang.msg101')}}:{{this.form.rechargeId}}</div>
                                    <div  class="record-finish-commet" >{{$t('recharge.msg5')}}</div>
                            </div>
                            <div class="padding">
                                <cube-button  @click="back" class="reg-button blue-btn" >{{$t('lang.msg102')}}</cube-button>
                            </div>
                        </div>
                    </transition>
                </cube-scroll>
                </div>
            </form>
        </div> 
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiRechargeInfo,apiRechargeBank,apiRechargeThird } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
import { validator } from '@/utils/validator.js'
import Clipboard from 'clipboard';
import VueQr from 'vue-qr'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            ShowCoNum:'',
            Wallet:'',
            check1:false,
            nowStep:1,
			min_amount: 100,
			max_amount: 30000,
			thirdMaxAmount: 100,
			thirdMinAmount: 30000,
            thirdRate:0,
            recharge:null,
			form:{
				bank:'',
                username:'',
                userbankid:'',
                remark:'',
                memo: this.$faker().random.number(999999),
                qrcode:'',
                rechargeId:'',
                thirdID:'',
                pic:'',
                rate:0,
            },
            rules1:{
                type: 'number',
                required: true,
                min:100,
                max:30000
            },
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: '', //'Resfresh Success',
            },
            selectedLabelDefault:1,
            showTab:true,
            showPay1:true,
            showPay2:false,   
        }
    },  
    methods: {
        uploaderr()
        {
            this.toast(this.$t('lang.msg191'),null,3000);
        },
        uploaded(obj)
        {
            if(obj.response.status)
            {
                this.form.pic = obj.response.data
            }else{
                this.toast(this.$t('lang.msg191'),null,3000);
            }
        },
        filesAdded(files)
        {
            if(this.form.pic != "")
            {
                files[0].ignore = true
                this.$createToast({
                  type: 'warn',
                  time: 3000,
                  txt: this.$t('lang.msg192')
                }).show()
            }
              let hasIgnore = false
              const maxSize = 10 * 1024 * 1024 // 10M
              for (let k in files) {
                const file = files[k]
                if (file.size > maxSize) {
                  file.ignore = true
                  hasIgnore = true
                }
              }
              hasIgnore && this.$createToast({
                type: 'warn',
                time: 3000,
                txt:  this.$t('lang.msg193')
              }).show()
        },
        back()
        {
            this.$emit('switchView');
        },
        third_finish()
        {
            if(!validator.num_pos_int(this.form.amount))
            {
                
                this.toast(this.$t('lang.err4'));
                return;
            }
            // if(this.form.pic=="")
            // {
                
            //     this.toast(this.$t('lang.err9'));
            //     return;
            // }
            if(this.form.amount < this.thirdMinAmount || this.form.amount > this.thirdMaxAmount)
            {
                this.toast(this.$t('lang.err5'+ this.thirdMinAmount + ' ~ ' + this.thirdMaxAmount));
                return;
            }
            this.toast(this.$t('lang.msg106'),null,0); // 防使用者連點
            var newWindow = window.open("about:blank", '');
            apiRechargeThird({
                    amount: this.form.amount,
                    code: this.form.bank,
                    thirdID : this.form.thirdID,
                    pic: this.form.pic,
                })
                .then(response => {
                    this.$createToast().hide();
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if (response.data.status) {
                        this.form.rechargeId=response.data.data.rechargeId;
                        var recharge_url = '';
                        if(!response.data.data.url)
                        {
                            this.toast(this.$t('lang.err7'),null,0)
                            return;
                        }
                        if(this.form.thirdID==6)
                        {
                            //window.open(this.config.ApiUrl+"/third/usdt?html="+response.data.data.url,'', '');
                            recharge_url = this.config.ApiUrl+"/third/usdt?html="+response.data.data.url;
                            this.nowStep++;
                        }else
                        {
                            //window.open(response.data.data.url,'', '');
                            recharge_url = response.data.data.url;
                        }
                        newWindow.location.href = recharge_url;
                        this.nowStep++;
                    }
                    return;
                })
                .catch(error => {
                    this.$createToast().hide();
                    this.toast(error,null,0)
                    console.log(error);
                });
        },
        clickHandler(current)
        {
            this.nowStep=1;
			this.form={
				bank:'',
                username:'',
                userbankid:'',
                remark:'',
                memo: this.$faker().random.number(999999),
                qrcode:'',
                rechargeId:'',
                thirdID:this.form.thirdID,
                pic:'',
                rate:0,
            };
            switch(current)
            {
                case 1:
                    this.showPay1=true;
                    this.showPay2=false;
                    break;
                case 2:
                    this.showPay1=false;
                    this.showPay2=true;
                    this.rules1.max= this.thirdMaxAmount
                    this.rules1.min= this.thirdMinAmount
                    break;
            }
        },
        change(value, index)
        {
            this.form.username=this.recharge.bank[index].AccountName;
            this.form.userbankid=this.recharge.bank[index].Account;
            this.form.remark=this.recharge.bank[index].Remark;
            this.form.qrcode=this.recharge.bank[index].Qrcode;
            this.form.rate=this.recharge.bank[index].Rate;
			this.min_amount= this.recharge.bank[index].MinAmount
			this.max_amount= this.recharge.bank[index].MaxAmount
            this.rules1.min= this.recharge.bank[index].MinAmount
            this.rules1.max= this.recharge.bank[index].MaxAmount
        },
        copy(str)
        {
            var clipboard = new Clipboard(".copy-btn", {
            text: ()=>{
                    return str;
                }
            });
            clipboard.on('success', e => {
                this.toast(this.$t('lang.msg107'),null,1000);
                clipboard.destroy()
                if(e)
                {
                    console.log(e)
                }
            })
            clipboard.on('error', e => {
                clipboard.destroy()
                if(e)
                {
                    console.log(e)
                }
            })
        },
        finish()
        {
            if(!validator.num_pos_int(this.form.amount))
            {
                this.toast(this.$t('lang.err4'));
                return;
            }
            if(this.form.amount < this.min_amount || this.form.amount > this.max_amount)
            {
                this.toast(this.$t('lang.err5'+ this.min_amount + ' ~ ' + this.max_amount));
                return;
            }
            this.toast(this.$t('lang.msg106'),null,0); // 防使用者連點
            apiRechargeBank({
                    amount: this.form.amount,
                    bankid: this.form.bank,
                    memo: this.form.memo,
                    pic: this.form.pic,
                })
                .then(response => {
                    this.$createToast().hide();
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if (response.data.status) {
                        this.nowStep++;
                        this.form.rechargeId=response.data.data.rechargeId;
                    }
                    return;
                })
                .catch(error => {
                    this.$createToast().hide();
                    console.log(error);
                });
        },
        getRecharge()
        {
            apiRechargeInfo({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data)
                        {
                            this.recharge=response.data.data;
                            this.Wallet=response.data.data.wallet;
                        }
                        //if(this.recharge.bank.length < 1 || this.recharge.third.length < 1)
                        if(this.recharge.bank || this.recharge.third)
                        {
                            //this.showTab = false;
                            switch(true)
                            {
                                //case this.recharge.bank.length > 0:
                                case this.recharge.bank:
                                    this.clickHandler(1);
                                    break;
                                //case this.recharge.third.length > 0:
                                case this.recharge.third:
                                    this.clickHandler(2);
                                    break;
                            }
                        }
                        //if(this.recharge.third.length > 0)
                        if(this.recharge.third)
                        {
                            this.form.thirdID = response.data.data.third_data.ID;
                            this.thirdMaxAmount  = response.data.data.third_data.MaxAmount;
                            this.thirdMinAmount  = response.data.data.third_data.MinAmount;
                            this.thirdRate = response.data.data.third_data.Rate;
                        }
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                });
            if(this.$refs.scroll)
            {
                this.$refs.scroll.forceUpdate(false);
            }
        },
        logRefresh(){
            setTimeout(() => {
                this.getRecharge();
            }, 1000);
        },       
    },
    watch:{
        'form.amount':function(val){
            const result = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.$nextTick(() => this.ShowCoNum = result);
        }
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        tabs()
        {
            let data= 
            [
                {
                    label: this.$t('lang.msg103'),
                    value:1,
                },
                {
                    label: this.$t('lang.msg104'),
                    value:2,
                },
            ];
            return data;
        },
        scrollLogOpts() 
        {
            return {
                pullUpLoad: false,
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                scrollbar: false,
            }
        },
        bank_array()
        {
            var data=[];
            if(this.recharge)
            {
                var lt=this.recharge.bank;
                for(var k in lt)
                {
                    data.push({value:lt[k].ID,text:lt[k].BankName});
                }
            }
            return data;
        },
        third_array()
        {
            var data=[];
            if(this.recharge)
            {
                var lt=this.recharge.third;
                for(var k in lt)
                {
                    data.push({value:lt[k].Code,text:lt[k].Name});
                }
            }
            return data;
        },
    },
    components: {
      MainPage,
      VueQr,
    },
    created() {
        this.getRecharge();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .mail-tab-bar
        top: 45px;
        bottom: unset;
        box-shadow: 0 0 2px $tab-border-bottom-color;
    .record-body
        position: fixed; 
        bottom: 0px;
        top: 100px;
        left: 0;
        right: 0;
        overflow:hidden;
    .setup-space
        margin-top 20px;
        margin-bottom: 20px;
        font-size 0.9rem;
        line-height 20px;
    .record-item
        .copy-btn
            background-color: $btn-bg-red-color
            color: $font-white-color
            border-radius: 5px;
            width: 50px;
            text-align: center;
            line-height: 40px;
    .record-qrcode
        margin: auto;
    .record-error
        display flex;
    .padding
        padding: 5px 10px;
    .record-info
        padding: 10px;
        .record-info-content
            background-color: $index-bg-color;
            padding: 15px;
            border-radius: 10px;
            text-align: left;
    .record-hint
        padding: 10px 15px;
        line-height: 25px;
        font-size: 0.4rem;
        ol
            list-style-type: decimal;
            margin-left: 12px;
            li
                list-style: unset;
</style>
