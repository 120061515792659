<template>
    <div slot="content" >
        <div class="main-index">
            <cube-scroll  ref="scroll" :data="results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore">
				<div v-for="(item, index) in results" class="record-item" :key="index" @click="onItemClick(index)" >
                    <div class="record-top" style="display: block;">
                        <div style="margin-bottom: 10px;">
                            {{ getMomentDate(item.CreatedAt)}}
                        </div>
                        <div class="record-title text-left">
                            <span class="read-font" :class="{'green-bgcolor':item.IsReaded,'red-bgcolor':!item.IsReaded}">{{ item.IsReaded ? $t('lang.msg151'): $t('lang.msg152')}}</span>
                            {{ item.Title }}
                        </div>
                    </div>
                    <div class="record-line">
                    </div>
					<div class="record-bot">
						<div class="col record-subtitle text-left">{{ item.Content }}</div>
					</div>                   
				</div>
                <div class="no-record" v-if="!results.length">
                    <img class="no-record-img" src="@/assets/images/user/norecord.png">
                    <div class="no-record-txt">{{$t('lang.msg35')}}</div>
                </div>
            </cube-scroll> 
        </div>
        <div v-if="showDetail" >
            <div class="news-layout">
                <div style="text-align: end;"><span class="markx"><i class="fa-solid fa-xmark" @click="closeClick"></i></span></div>
                <cube-scroll  ref="scroll2" >
                <div style="margin-top: 10px;font-size: 16px;line-height: 20px; padding: 0px 15px;">
                    {{Detail.Title}}
                </div>
                <!-- <div v-html="Detail.Content" style="margin-top: 10px;font-size: 16px;line-height: 20px;"></div> -->
               <div style="margin-top: 10px;margin-bottom: 10px;;font-size: 16px;line-height: 20px; padding: 0px 15px;">
                    <pre>{{Detail.Content}}</pre>
                </div>
                </cube-scroll>
            </div>
            <div class="keyboard_block" ></div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import { apiMessage,apiMessageRead } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            showDetail:false,
            Detail:{
                Title:'',
                Content:'',
            },
            results:[],
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: '',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
        }
    },
    methods: {      
        getLog(page)
        {
            apiMessage({
                page: page,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.results.splice(0,this.results.length);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        this.results=this.results.concat(response.data.data);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        closeClick(){
            this.showDetail=0;
        },
        onItemClick(index)
        {
            //this.dialog('alert',this.results[index].Title,this.results[index].Content);
            this.showDetail=1;
            this.Detail.Title = this.results[index].Title
            this.Detail.Content = this.results[index].Content
            this.$refs.scroll2.refresh()
            if(this.results[index].IsReaded==0)
            {
                apiMessageRead({
                    id: this.results[index].ID,
                    })
                    .then(response => {
                        if(!response.data.status)
                        {
                            this.error_handler(response.data);
                        }
                        if(response.data.status)
                        {
                            this.results[index].IsReaded=1;
                        }
                        return;
                    }).catch(error => {
                        console.log(error);
                        return;
                    });
            }
        },
        logRefresh(){
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore(){
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },        
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.log.pullUpLoadNoMoreTxt
                    }
                },
            }
        },        
    },
    components: {
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    top: 85px;
    .read-font
        color: #fff;
        font-size: 16px;
        border-radius: 5px;
        line-height: 1;
        padding: 3px 6px;
    .record-line
        height 1px;
        border-bottom: 1px $border-bottom-color solid;
    .record-bot
        padding-bottom: 5px;
        .col
            width 100%;
        .record-subtitle
            line-height: 20px;
            font-size: 16px;
            white-space : nowrap;
            text-overflow: ellipsis;
            width :100%
            overflow: hidden;
            padding-top: 10px;
    .record-top
        display flex;
        padding-bottom: 5px;
    .record-item
        padding: 10px 10px;
        margin-bottom: 10px;
        display block
</style>
