<template>
    <MainPage :title="$t('lang.msg67')"  v-on:callmore="more" v-on:callback="back">
        <div slot="content">
            <div class="main-index">
                <cube-scroll  ref="scroll" :data="prodlog.results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore">
                <div class="record-time-zone">
                    <i class="fas fa-clock " ></i><div class="time-padding">{{filterData.StartValue}}</div> ~ <div class="time-padding">{{filterData.EndValue}}</div>
                </div>
                <div v-for="(item, index) in prodlog.results" class="record-block" :key="index" >
                    <div class="record-item">
                        <div style="margin: 0px 10px;">
                            <div class="record-prod-name">{{item.Name}}</div>
                            <img class="imgIcon" :src="require('@/assets/images/prod/'+item.ProdID+'.png')">
                        </div>
                        <div style="width: 100%;">
                            <div class="record-content">
                                <div class="record-txt" :class="{'tag-color-0':item.Status==0,'tag-color-1':item.Status==1}">{{getProdStatus(item.Status)}}</div>
                            </div>
                            <div class="record-content">
                                <div class="record-txt">{{$t('lang.msg64')}}: {{item.Price}}</div>
                            </div>
                            <div class="record-content">
                                <div class="record-txt">{{$t('lang.msg30')}}: {{item.Bonus}}%</div>
                            </div>
                            <div class="record-content">
                                <div class="record-txt">{{$t('lang.msg65')}}: {{getMomentDate(item.CreatedAt)}}</div>
                            </div>
                            <div class="record-content">
                                <div class="record-txt">{{$t('lang.msg66')}}: {{getMomentDate(item.BonusDay)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-record" v-if="!prodlog.results.length">
                    <img class="no-record-img" src="@/assets/images/user/norecord.png">
                    <div class="no-record-txt">{{$t('lang.msg35')}}</div>
                </div>
                </cube-scroll>  
            </div>
            <transition name="filtermenu">
                <component :is="default_view" v-if="show_view" v-on:callback="search" v-on:showSearch="showSearch"></component>
            </transition>
        </div>
    </MainPage>
</template>
<script>
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { mapGetters } from "vuex";
import { apiProdLog } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import moment from 'moment'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            show_view:false,
            filterSearch:0,
            filterData:{
                StartValue:moment(new Date().getTime() - (30 * 86400 * 1000)).format("YYYY-MM-DD"),
                EndValue:moment(new Date()).format("YYYY-MM-DD"),
                StatusValue:4
            },   
            prodlog:{
                results:[],
            },
            log: {
                y: 0,
                col: 2,
                data: [],

                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: ' ',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
        }
    },
    methods: {
        search(){
            this.show_view=false
        },
        more(){
            this.show_view=true
            this.default_view=require('@/components/user/prodlog-search.vue').default;
        },
        back()
        {
          //this.$router.back()
          this.$emit('switchView');
        },
        showSearch(value,data)
        {
            this.show_view=false
            this.filterSearch=value;
            if(data!=null)
            {
                this.current_page=1;
                this.filterData=data;
                this.getLog(this.current_page);
            }
        },
        getProdStatus(type)
        {
            switch(type)
            {
                case 0:
                    return this.$t('lang.msg63');
                case 1:
                    return this.$t('lang.msg62');
            }
        },
        getLog(page)
        {
            apiProdLog({
                page: page,
                status: this.filterData.StatusValue,
                fromTime: this.filterData.StartValue,
                toTime: this.filterData.EndValue,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.prodlog.results.splice(0,this.prodlog.results.length);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }                        
                        this.prodlog.results=this.prodlog.results.concat(response.data.data);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        logRefresh(){
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore(){
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
    },
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.$t('lang.err3')
                    }
                },
                scrollbar: true
            }
        },
    },
    components: {
        MainPage,
    },
    created() {
        this.getLog(1);
    }
}

</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .tag-color-0
        color $font-blue-color;
    .tag-color-1
        color $font-green-color;
    .record-block
        margin-top 15px
        margin-left 15px
        margin-right 15px
        padding: 5px;
        border-radius 5px
        // box-shadow:0px 0px 2px $bid-1-border-color
        border 1px $prod-border-color solid
        background-color: $record-item-bg-color;
        .record-item
            align-items: center;
            padding unset
            .record-prod-name
                text-align: center;
                line-height: 25px
            .imgIcon
                width 100%
                border-radius: 5px
            .record-content
                line-height: 23px
                padding 0px 5px
                display inline-flex
                width: 100%;
                .record-txt
                    font-size 14px
</style>