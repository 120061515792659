<template>
  <MainPage :title="$t('lang.msg56')" :nomore="true" v-on:callback="back">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <div class="record-body">
                <cube-scroll ref="scroll" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh">
                    <div>
                        <div class="record-item" @click="onItemClick(1)" >
                            <div class="col50 text-left">{{$t('lang.msg68')}}</div>
                            <div class="col50 text-right"><i class="icon cubeic-arrow"></i></div>
                        </div>
<!--                        <div class="record-item" @click="onItemClick(2)">
                            <div class="col50 text-left">{{$t('lang.msg69')}}</div>
                            <div class="col50 text-right"><span v-if="!safe.CoinPassword">{{$t('lang.msg73')}}</span><i class="icon cubeic-arrow"></i></div>
                        </div> -->
                        <div class="record-item" @click="onItemClick(3)">
                            <div class="col50 text-left">{{$t('lang.msg70')}}</div>
                            <div class="col50 text-right"><span v-if="safe.Phone==''">{{$t('lang.msg73')}}</span><span v-else>{{safe.Phone}}</span><i class="icon cubeic-arrow"></i></div>
                        </div>
                        <div class="record-item" @click="onItemClick(4)">
                            <div class="col50 text-left">{{$t('lang.msg71')}}</div>
                            <div class="col50 text-right"><span v-if="safe.Email==''">{{$t('lang.msg73')}}</span><span v-else>{{safe.Email}}</span><i class="icon cubeic-arrow"></i></div>
                        </div>
<!--                        <div class="record-item" @click="onItemClick(5)">
                            <div class="col50 text-left">{{$t('lang.msg72')}}</div>
                            <div class="col50 text-right"><span v-if="safe.BankName==''">{{$t('lang.msg73')}}</span><span v-else>{{safe.BankName}}</span><i class="icon cubeic-arrow"></i></div>
                        </div>                        
                        <div class="record-item" @click="onItemClick(6)">
                            <div class="col50 text-left">{{$t('lang.msg199')}}</div>
                            <div class="col50 text-right">
                                <span v-if="user.Verified==0 || user.Verified==2">{{$t('lang.msg73')}}</span>
                                <span v-else>{{safe.RealName}} </span>
                                <i class="icon cubeic-arrow"></i>
                            </div>
                        </div> -->
                    </div>
                </cube-scroll>
            </div>
        </div>   
        <transition name="filtermenu">
            <keep-alive>
                <component :is="default_view" v-on:callback="switchView2" :safe="safe"></component>
            </keep-alive>
        </transition>
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import LoginPassword from '@/components/user/login-password.vue'
import CoinPassword from '@/components/user/coin-password.vue'
import Phone from '@/components/user/phone.vue'
import Email from '@/components/user/email.vue'
import Bank from '@/components/user/bank.vue'
import RealInfo from '@/components/user/realinfo.vue'
import { apiSafe } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            default_view:null,
            safe:{
                coinPassword:true,
                email:'',
                phone:'',
            },
            log: {
                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: '', //'Resfresh Success',
            },               
        }
    },
    methods: {
        switchView2()
        {
            this.getLog();
            this.default_view=null
        },
        back()
        {
          this.$emit('switchView');
        },
        getLog()
        {
            apiSafe({
                })
                .then(response => {
                    if(response.data.error)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data)
                        {
                            this.safe=response.data.data;
                        }
                    }
                    this.$refs.scroll.forceUpdate(false);
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate(false);
                    return;
                });
        },
        logRefresh(){
            setTimeout(() => {
                this.getLog();
            }, 1000);
        },
        onItemClick(index)
        {
            switch(index)
            {
                case 1:
                    this.default_view=LoginPassword;
                    return;
                case 2:
                    this.default_view=CoinPassword;
                    return;
                case 3:
                    this.default_view=Phone;
                    return;
                case 4:
                    this.default_view=Email;
                    return;
                case 5:
                    this.default_view=Bank;
                    return;
                case 6:
                    this.default_view=RealInfo;
                    return;
            }
        },      
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        scrollLogOpts() {
            return {
                pullUpLoad: false,
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                scrollbar: false,
            }
        },        
    },
    components: {
      MainPage
    },
    created() {
        this.getLog();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-item
        display flex;
        margin-bottom 1px;
        padding: 5px 10px;
        border-bottom: 1px $border-bottom-color solid;
        background-color: $prod-black-color;
        font-size: 14px;
    .icon
        margin-left 10px;
</style>
