<template>
  <MainPage :title="$t('lang.msg59')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <cube-tab-bar v-model="selectedLabelDefault"
                            show-slider
                            @click="clickHandler"
                            :data="tabs" class="mail-tab-bar">
            </cube-tab-bar>
        </div>   
        <transition name="filtermenu">
                <keep-alive>
                    <BulletinList v-if="showBulletin"  ></BulletinList>
                </keep-alive>
        </transition>
        <transition name="filtermenu">
                <keep-alive>
                    <LetterList v-if="showLetter"  ></LetterList>
                </keep-alive>
        </transition>

    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import BulletinList from '@/components/user/bulletin.vue'
import LetterList from '@/components/user/letter.vue'
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            selectedLabelDefault:1,
            showBulletin:true,
            showLetter:false,
        }
    },
    methods: {
        back()
        {
            this.$emit('switchView');
        },
        clickHandler(current)
        {
            switch(current)
            {
                case 1:
                    this.showBulletin=true;
                    this.showLetter=false;
                    break;
                case 2:
                    this.showBulletin=false;
                    this.showLetter=true;
                    break;
            }
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }), 
        tabs()
        {
            return [
                {
                    label: this.$t('lang.msg137'),
                    value:1,
                },
                {
                    label: this.$t('lang.msg138'),
                    value:2,
                },
            ]
        },
    },
    components: {
      MainPage,
      BulletinList,
      LetterList,
    },
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .mail-tab-bar
        top: 45px;
        bottom: unset;
</style>
