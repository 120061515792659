<template>
    <MainPage :title="$t('lang.msg36')"  v-on:callmore="more" v-on:callback="back">
        <div slot="content">
            <div class="main-index main-bg-def">
                <cube-scroll  ref="scroll" :data="transLog.results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" @pulling-up="logLoadMore">
                <div class="record-time-zone">
                    <!-- <i class="fas fa-clock " ></i><div class="time-padding">{{getMomentYDate(filterData.StartValue)}}</div> ~ <div class="time-padding">{{getMomentYDate(filterData.EndValue)}}</div> -->
                    <i class="fas fa-clock " ></i><div class="time-padding">{{filterData.StartValue}}</div> ~ <div class="time-padding">{{filterData.EndValue}}</div>
                </div>
                <div v-for="(item, index) in transLog.results" class="record-block" :key="index" >
                    <div class="record-item">
                        <div style="margin-right: 10px;">
                            {{getMomentYDate(item.CreatedAt)}} {{getMomentTime(item.CreatedAt)}}
                        </div>
                        <div class="record-content">
                            <div class="record-txt">{{$t(config.wallet_type[item.TypeCoin])}}</div>
                        </div>
                    </div>
                    <div class="record-item">
                        <div class="record-content">
                            <div class="record-txt" :class="{'plus-color':item.Coin >= 0,'less-color':item.Coin < 0}">{{item.Coin  | numFilter}}</div>
                        </div>
                        <div class="record-content">
                            <div class="record-txt">{{item.UserCoin  | numFilter}}</div>
                        </div>
                    </div>
                    <div class="record-item">
                        <div class="record-content">
                            <div class="record-txt" >{{item.SourceName }}</div>
                        </div>
                        <div class="record-content">
                            <div class="record-txt" style="text-align: center;">{{getTransStatus(item.Type)}}</div>
                        </div>
                    </div>
                </div>
                <div class="no-record" v-if="!transLog.results.length">
                    <img class="no-record-img" src="@/assets/images/user/norecord.png">
                    <div class="no-record-txt">{{$t('lang.msg35')}}</div>
                </div>
                </cube-scroll>
            </div>
            <transition name="filtermenu">
                <component :is="default_view" v-if="show_view" v-on:callback="search" v-on:showSearch="showSearch"></component>
            </transition>
        </div>
    </MainPage>
</template>
<script>
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { mapGetters } from "vuex";
import { apiTransLog } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import moment from 'moment'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            show_view:false,
            filterSearch:0,
            filterData:{
                StartValue:moment().subtract(7,'d').format("YYYY-MM-DD"),
                EndValue:moment(new Date()).format("YYYY-MM-DD"),
                StatusValue:0
            },   
            transLog:{
                results:[],
            },
            log: {
                y: 0,
                col: 2,
                data: [],

                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: ' ',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
        }
    },
    methods: {
        getTransStatus(type)
        {
            return this.$t(this.config.cash_type[type])
        },
        search()
        {
            this.show_view=false
        },
        more()
        {
            this.show_view=true
            this.default_view=require('@/components/user/translog-search.vue').default;
        },
        back()
        {
          this.$emit('switchView');
        },
        showSearch(value,data)
        {
            this.show_view=false
            this.filterSearch=value;
            if(data!=null)
            {
                this.current_page=1;
                this.filterData=data;
                this.getLog(this.current_page);
            }
        },
        getLog(page)
        {
            apiTransLog({
                page: page,
                status: this.filterData.StatusValue,
                fromTime: this.restYMD(this.filterData.StartValue),
                toTime: this.restYMD(this.filterData.EndValue),
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.transLog.results.splice(0,this.transLog.results.length);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }                        
                        this.transLog.results=this.transLog.results.concat(response.data.data);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        logRefresh(){
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
        logLoadMore(){
            this.current_page++;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },
    },
    computed: {
        ...mapGetters({
            user: "user",
            config: "config",
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: {
                    threshold: parseInt(this.log.pullUpLoadThreshold),
                    txt: {
                        more: this.log.pullUpLoadMoreTxt,
                        noMore: this.$t('lang.err3')
                    }
                },
                scrollbar: true
            }
        },
    },
    components: {
        MainPage,
    },
    created() {
        this.getLog(1);
    }
}

</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-block
        margin 0px 20px
        border-bottom 1px $border-bottom-color solid
        background-color: $prod-black-color;
        .record-item
            justify-content: space-between;
            border-bottom unset
            padding: 10px 20px;
            font-size: 14px;
            .plus-color
                color $plus-color
            .less-color
                color #E64340
            .record-content
                .record-txt
                    font-size .38rem
</style>