<template>
  <MainPage :title="$t('lang.msg69')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <form>
                <div class="record-body">
                <cube-scroll  ref="scroll" >
                    <div v-if="safe.CoinPassword">
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg81')}}</div>
                                <div class="col60 ">
                                    <cube-input v-model="form.pwd"
                                        :placeholder="$t('lang.msg81')"
                                        type="password"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                            <div>
                                <cube-validator v-model="check1" :model="form.pwd" :rules="rules" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg82')}}</div>
                                <div class="col60 ">
                                    <cube-input v-model="form.new_pwd"
                                        :placeholder="$t('lang.msg82')"
                                        type="password"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                            <div>
                                <cube-validator v-model="check2" :model="form.new_pwd" :rules="rules" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg76')}}</div>
                                <div class="col60 ">
                                    <cube-input v-model="form.confirm_pwd"
                                        :placeholder="$t('lang.msg76')"
                                        type="password"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                            <div>
                                <cube-validator v-model="check3" :model="form.confirm_pwd" :rules="rules" class="error-msg"></cube-validator>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="record-item">
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg82')}}</div>
                                <div class="col60 ">
                                    <cube-input v-model="form.pwd"
                                        :placeholder="$t('lang.msg82')"
                                        type="password"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                            <div>
                                <cube-validator v-model="check1" :model="form.pwd" :rules="rules" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg76')}}</div>
                                <div class="col60 ">
                                    <cube-input v-model="form.confirm_pwd"
                                        :placeholder="$t('lang.msg76')"
                                        type="password"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                            </div>
                            <div>
                                <cube-validator v-model="check3" :model="form.confirm_pwd" :rules="rules" class="error-msg"></cube-validator>
                            </div>
                        </div>
                    </div>
                    <div class="padding">
                        <cube-button  @click="up" class="reg-button blue-btn" :disabled="!check3 || !check1" :class="{'disabled-btn':!check3 || !check1 }">{{$t('lang.msg24')}}</cube-button>
                    </div>
                </cube-scroll>
                </div>
            </form>
        </div> 
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiSafeUp } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            check1:false,
            check2:false,
            check3:false,
            form:{
                pwd:'',
                new_pwd:'',
                confirm_pwd:'',
            },
            rules:{
                type: 'string',
                required: true,
                min:4,
            },
        }
    },
    props: ['safe'],
    methods: {
        back()
        {
          this.$emit('callback',0);
        },   
        up()
        {
            if(this.safe.CoinPassword)
            {
                if(this.form.new_pwd != this.form.confirm_pwd){
                    this.toast(this.$t('lang.msg80'));
                    return;
                }
                if(this.form.new_pwd == this.form.pwd){
                    this.toast(this.$t('lang.msg79'));
                    return;
                }
            }
            if(!this.safe.CoinPassword)
            {
                if(this.form.pwd != this.form.confirm_pwd){
                    this.toast(this.$t('lang.msg80'));
                    return;
                }
            }
            apiSafeUp({
                type:2,
                coinpassword:this.form.pwd,
                confirmCoinpassword:this.form.confirm_pwd,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.toast(this.$t('lang.msg78'));
                        this.$emit('callback',0);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        }
    },
    computed: {
		...mapGetters({
            config:'config',
        }),     
    },
    components: {
      MainPage
    },
    created() {
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-item
        margin-bottom 1px;
        padding: 5px 15px;
        display block
        .record-top
            display flex;
        .error-msg
            padding-top 5px;
            text-align: center;
    .padding
        padding: 20px 10px;
    .reg-button
        margin-top 10px;
</style>
