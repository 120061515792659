<template>
  <MainPage :title="$t('lang.msg71')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <div class="record-body">
            <cube-scroll  ref="scroll" >
            <form>
                <div class="record-item"  >
                    <div class="record-top">
                        <div class="col40 text-left">{{$t('lang.msg88')}}</div>
                        <div class="col60 " v-if="safe.Email==''">
                            <cube-input v-model="form.email"
                                :placeholder="$t('lang.msg89')"
                                type="text"
                                :clearable="{visible: true, blurHidden: true}"
                                autocomplete="false"
                                >
                            </cube-input>
                        </div>
                        <div class="col60 " v-else>
                            <cube-input v-model="safe.Email"
                                placeholder=""
                                type="text"
                                :clearable="{visible: true, blurHidden: true}"
                                class="disabled-color"
                                autocomplete="false"
                                :disabled="true"
                                >
                            </cube-input>
                        </div>                                
                    </div>
                    <div>
                        <cube-validator v-model="check1" :model="form.email" :rules="rules" class="error-msg"></cube-validator>
                    </div>
                </div>
                <div class="padding">
                    <cube-button v-if="safe.Email==''"  @click="up" class="reg-button blue-btn" :disabled=" !check1 " :class="{'disabled-btn':!check1 }">{{$t('lang.msg24')}}</cube-button>
                    <cube-button v-else :disabled="true" class="reg-button blue-btn disabled-btn" >{{$t('lang.msg24')}}</cube-button>
                </div>
                <div class="record-info">
                    {{$t('lang.msg85')}}
                </div>
            </form>
            </cube-scroll>
            </div>
        </div> 
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiSafeUp } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            check1:false,
            form:{
                email:'',
            },
            rules:{
                type: 'email',
                required: true,
            },
        }
    },
    props: ['safe'],
    methods: {
        back()
        {
          this.$emit('callback',0);
        },   
        up()
        {
            if(this.safe.Email != ''){
                this.toast(this.$t('lang.msg87'));
                return;
            }
            apiSafeUp({
                type:4,
                email:this.form.email,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.toast(this.$t('lang.msg78'));
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        }
    },
    computed: {
		...mapGetters({
            config:'config',
        }),     
    },
    components: {
      MainPage
    },
    created() {
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-item
        margin-bottom 1px;
        padding: 5px 15px;
        display block
        .record-top
            display flex;
        .error-msg
            text-align center;
            padding-top 5px;
    .record-info
        padding-top 15px;
        text-align center;
        font-size 16px;
</style>
