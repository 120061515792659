<template>
  <MainPage :title="$t('lang.msg61')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <form class="qr-form">
                <div class="qr-img-block" style="text-align: center;">
                    <div class="qr-scan">{{$t('lang.msg155')}}</div>
                    <vue-qr :text="qr_url" :size="200"></vue-qr>
                </div>
                <div class="qr-url-block">
                    <div class="title fw700">{{$t('lang.msg156')}}&nbsp;&nbsp;&nbsp;</div>
                    <div class="content" >
                        {{qr.code}}
                    </div>&nbsp;
                    <span slot="prepend"  class="fas fa-copy reg-icon copyBtn1" type="text" @click="copy(qr_lid)"></span>
                </div>
                
                <div class="qr-url-block">
                    <div class="title fw700">{{$t('lang.msg157')}}&nbsp;&nbsp;&nbsp;</div>
                    <div class="content url">
                        {{qr_url}}
                    </div>&nbsp;
                    <span slot="prepend"  class="fas fa-copy reg-icon copyBtn1" type="text" @click="copy(qr_url)"></span>
                </div>
                <div class="qr-url-block">
                    <div class="title fw700">{{$t('lang.msg158')}}&nbsp;&nbsp;&nbsp;</div>
                    <div class="content">
                        {{qr.used}}
                    </div>
                </div>
            </form>
        </div>
        <AgentTabBar></AgentTabBar>
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import AgentTabBar from '@/components/agent/agent-tab-bar.vue'
import { mapGetters } from "vuex";
import { apiAgentQR } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import VueQr from 'vue-qr'
import Clipboard from 'clipboard';
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            check:false,
            qr:{
                used:0,
                code:'',
            },
            rules:{
                type: 'number',
                required: true,
                mix: 0,
            },
        }
    },
    methods: {
        back()
        {
            this.$parent.back();
        },
        copy(str)
        {
            var clipboard = new Clipboard(".copyBtn1", {
            text: ()=>{
                    return str;
                }
            });
            clipboard.on('success', e => {
                this.toast(this.$t('lang.msg107'));
                clipboard.destroy()
                if(e)
                {
                    console.log(e)
                }
            })
            clipboard.on('error', e => {
                clipboard.destroy()
                if(e)
                {
                    console.log(e)
                }
            })
        },
    },
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
        qr_url()
        {
            //return "https://"+this.user.username+"."+this.config.domain_name+"/spreg/"+this.qr.lid;
            //console.log(window.location.hostname)
            return this.config.InviteUrl+"/#/reg/"+this.qr.code;
        },
        qr_lid()
        {
            return this.qr.code.toString();
        },        
    },   
    components: {
        MainPage,
        VueQr,
        AgentTabBar
    },
    created() {
        apiAgentQR({
        })
        .then(response => {
            if(!response.data.status)
            {
                this.toast(response.data.error,null);
                return;
            }
            if(response.data.status)
            {
                this.qr=response.data.data;
                return;
            }
            return;
        }).catch(error => {
            console.log(error);
            return;
        });
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .qr-img-block
        margin: auto;
        width: 100%;
        text-align: left;
        padding 10px 0px;
        .qr-scan
            font-size 18px;
            margin-bottom 10px;
    .qr-form
        width: 100%;
    .qr-url-block
        display: flex;
        width: 100%;
        padding: 15px 0px 15px 10px;
        text-align: left;
        line-height: 20px;
        overflow hidden;
        .title
            font-size: 14px;
            width: 82px;
            line-height: 38px;
        .content
            font-size: 14px;
            padding: 8px;
            border: 1px #707070 solid;
            border-radius: 5px;
            overflow hidden;
            width: 170px;
        .url
            font-size 14px;
    .reg-icon
        font-size: 24px;
        padding-left: 5px;
        color: #409eff;
        line-height: 34px;
    .pen-icon
        font-size: 24px;
        padding-left: 5px;
        color: #888;
    .reg-field
        font-size: .9rem;
        padding: 5px 0px 0px 12px;
        width 100px;
        width: 65px;
        text-align: center;  
    .padding
        padding: 10px;
    .error-msg
        padding: 5px;
</style>
