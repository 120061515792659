<template>
  <MainPage :title="$t('lang.msg199')" v-on:callback="back" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <form>
                <div class="record-body">
                <cube-scroll  ref="scroll" >
                        <div class="record-item" style="padding: 15px;
    text-align: center;" v-if="user.Verified==2">{{$t('lang.msg249')}}</div>
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg200')}}</div>
                                <div class="col60 " v-if="safe.RealName==''" >
                                    <cube-input v-model="form.RealName"
                                        :placeholder="$t('lang.msg94')"
                                        type="text"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                                <div class="col60 " v-else>
                                    <cube-input v-model="safe.RealName"
                                        placeholder=""
                                        type="text"
                                        class="disabled-color"
                                        autocomplete="false"
                                        :disabled="true"
                                        >
                                    </cube-input>
                                </div>                                
                            </div>
                            <div>
                                <cube-validator v-model="check1" :model="form.RealName" :rules="rules1" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        <div class="record-item"  >
                            <div class="record-top">
                                <div class="col40 text-left">{{$t('lang.msg201')}}</div>
                                <div class="col60 " v-if="safe.RealID==''" >
                                    <cube-input v-model="form.RealID"
                                        :placeholder="$t('lang.msg94')"
                                        type="text"
                                        autocomplete="false"
                                        >
                                    </cube-input>
                                </div>
                                <div class="col60 " v-else>
                                    <cube-input v-model="safe.RealID"
                                        placeholder=""
                                        type="text"
                                        class="disabled-color"
                                        autocomplete="false"
                                        :disabled="true"
                                        >
                                    </cube-input>
                                </div>                                
                            </div>
                            <div>
                                <cube-validator v-model="check2" :model="form.RealID" :rules="rules1" class="error-msg"></cube-validator>
                            </div>
                        </div>
                        
                        <div class="record-item" v-if="safe.RealName==''">
                            <cube-upload
                                ref="upload"
                              action="/api/comm/Upload?type=realinfo"
                              :simultaneous-uploads="1"
                              :max="2"
                              :min="2"
                              accept="*"
                              :auto="false"
                              @files-added="filesAdded"
                              @file-success="uploaded"
                              @file-error="uploaderr"
                              @file-removed="uploadrem"
                               />                                    
                        </div>  
<!--                        <div style="line-height: 30px;">
                            <div style="text-align: center;">{{$t('lang.msg234')}}</div>
                            <div style="text-align: center;">only jpg,png. limit 10M</div>
                        </div> -->
                        <div style="line-height: 30px;">
                            <div style="text-align: center;">{{$t('lang.msg234')}}</div>
                            <div style="text-align: center;">limit 10M</div>
                        </div>
                    <div class="padding">
                        <!-- <cube-button v-if="safe.RealName==''"  @click="up" class="reg-button blue-btn" :disabled=" !check1 || !check2 " :class="{'disabled-btn':!check1 || !check2 }">{{$t('lang.msg24')}}</cube-button> -->
                        <!-- <cube-button v-if="safe.RealName=='' && !isUploading"  @click="uppic" class="reg-button blue-btn" :disabled=" !check1 || !check2 || this.CountSelPic < 2" :class="{'disabled-btn':!check1 || !check2 || this.CountSelPic < 2}">{{$t('lang.msg24')}}</cube-button> -->
                        <cube-button v-if="safe.RealName=='' && !isUploading"  @click="uppic" class="reg-button blue-btn" >{{$t('lang.msg24')}}</cube-button>
                        <cube-button v-if="isUploading && safe.RealName==''"  :disabled="true" class="reg-button blue-btn disabled-btn">Uploading</cube-button>
                        <cube-button v-if="safe.RealName!=''" :disabled="true" class="reg-button blue-btn disabled-btn" >{{$t('lang.msg24')}}</cube-button>
                    </div>
                    <div class="record-info">
                        {{$t('lang.msg85')}}
                    </div>                    
                </cube-scroll>
                </div>
            </form>
        </div> 
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiSafeUp } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
//import { validator } from '@/utils/validator.js'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            check1:false,
            check2:false,
            // check3:false,
            // check4:false,
            CountPic:0,
            CountSelPic:0,
            bank_list:null,
            isUploading: false,
            form:{
                RealName:'',
                RealID:'',
            },
            rules1:{
                type: 'string',
                required: true,
            },          
        }
    },
    props: ['safe'],
    methods: {
        uploaderr()
        {
            this.toast(this.$t('lang.msg191'),null,3000);
            this.isUploading = false
        },
        uploaded(obj)
        {
            if(obj.response.status)
            {
                this.CountPic ++
                if(this.CountPic > 1)
                {
                    this.up()
                }
                //this.up()
            }else{
                this.toast(this.$t('lang.msg191'),null,3000);
            }
        },
        uploadrem(){
            this.CountSelPic --
        },
        filesAdded(files)
        {
            this.CountSelPic ++
            // console.log(this.CountSelPic, this.form.RealName , this.form.RealID)
            // if(this.CountSelPic >= 2 && this.form.RealName != '' && this.form.RealID != ''){
            //     this.isUploading = true
            //     console.log(this.isUploading)
            // }
            if(this.CountPic > 4)
            {
                files[0].ignore = true
                this.$createToast({
                  type: 'warn',
                  time: 3000,
                  txt: this.$t('lang.msg203')
                }).show()
            }
              let hasIgnore = false
              const maxSize = 10 * 1024 * 1024 // 10M
              for (let k in files) {
                const file = files[k]
                if (file.size > maxSize) {
                  file.ignore = true
                  hasIgnore = true
                }
              }
              hasIgnore && this.$createToast({
                type: 'warn',
                time: 3000,
                txt:  this.$t('lang.msg193')
              }).show()
        },
        back()
        {
          this.$emit('callback',0);
        },
        uppic(){
            // if(this.CountSelPic < 2){
            //     this.toast(this.$t('lang.msg234'));
            //     return;                
            // }
            if(this.safe.RealName != ''){
                this.toast(this.$t('lang.msg204'));
                return;
            }
            if(this.form.RealName == "" ){
                //this.toast(this.$t('lang.msg234'));
                this.toast("Please input Real Name");
                return;                
            }
            if(this.form.RealID == ""){
                //this.toast(this.$t('lang.msg234'));
                this.toast("Please input ID");
                return;                
            }
            this.isUploading = true
            this.toast('Loading...',null,0,'loading');
            this.$refs.upload.start()
        },
        up()
        {
            // if(this.CountPic < 2){
            //     this.toast(this.$t('lang.msg234'));
            //     return;                
            // }
            // if(this.safe.RealName != ''){
            //     this.toast(this.$t('lang.msg204'));
            //     return;
            // }
            apiSafeUp({
                type:7,
                RealName:this.form.RealName,
                RealID:this.form.RealID,
                })
                .then(response => {
                    this.$createToast().hide();
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                        this.isUploading = false
                    }
                    if(response.data.status)
                    {
                        this.toast(this.$t('lang.msg78'));
                        this.$emit('callback',0);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        // getBank()
        // {
        //     apiBank({
        //         })
        //         .then(response => {
        //             if(!response.data.status)
        //             {
        //                 this.toast(response.data.error);
        //                 return;
        //             }
        //             if(response.data.status)
        //             {
        //                 this.bank_list=response.data.data;
        //                 return;
        //             }
        //         }).catch(error => {
        //             console.log(error);
        //             return;
        //         });
        // }
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        bank_array(){
            var data=[];
            var lt=this.bank_list;
            // if(safe.bank_id!='')
            // {
            //     data.push({value:lt[k].id,text:lt[k].name});
            //     return 
            // }
            for(var k in lt)
            {
                data.push({value:lt[k].ID,text:lt[k].Name});
            }
            return data;
        },        
    },
    components: {
        MainPage
    },
    created() {
        //this.getBank();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-item
        margin-bottom 1px;
        padding: 5px 15px;
        display block
        .record-top
            display flex;
        .error-msg
            text-align center;
            padding-top 5px;
    .icon
        margin-left 10px;
    .padding
        padding: 20px 10px;
    .reg-button
        margin-top 10px;
    .record-info
        padding-top 15px;
        text-align center;
        font-size 15px;
</style>
