<template>
    <div>
        <cube-tab-bar v-model="$parent.$parent.$parent.$parent.selectedLabelDefault"
                        @click="clickHandler"
                        :data="tabs">
        </cube-tab-bar>
    </div>
</template>

<script>
import { Locale } from 'cube-ui'
import { globalMixin } from '@/mixin.js';
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            selectedLabelDefault:1,
        }
    },
    methods: {
        clickHandler(current)
        {
            this.$parent.$parent.$parent.$parent.agentPage(current);
        },
    },
    computed: {
        tabs()
        {
            return [
                {
                    label: this.$t('lang.msg140'),
                    icon: 'fas fa-user-friends icon-size',
                    value:1,
                },
                {
                    label: this.$t('lang.msg141'),
                    icon: 'fas fa-qrcode icon-size',
                    value:2,
                },
                // {
                //     label: this.$t('lang.msg142'),
                //     icon: 'fas fa-glass-cheers icon-size',
                //     value:3,
                // },
            ]
        },
    },
}
</script>

<style>
</style>
