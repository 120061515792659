<template>
  <MainPage :title="$t('lang.msg58')"  :nomore="true" v-on:callback="back">
    <div slot="content" >
        <div class="main-index main-bg-def">
            <cube-scroll  ref="scroll" :data="results" :options="scrollLogOpts" :scroll-events="['scroll']"  @pulling-down="logRefresh" >
				<div v-for="(item, index) in results" class="record-item" :key="index" @click="onItemClick(index)"  >
                    <div class="record-top">
                        <div class="col">
                            <img :src="config.ImgUrl+'banner/'+item.Img" class="record-img"/>
                        </div>
                    </div>
				</div>
                <div class="no-record" v-if="!results.length">
                    <img class="no-record-img" src="@/assets/images/user/norecord.png">
                    <div class="no-record-txt">{{$t('lang.msg35')}}</div>
                </div>
            </cube-scroll>
        </div>
        <div v-show="showDetail" >
            <div class="news-layout">
                <div style="text-align: end;"><span class="markx"><i class="fa-solid fa-xmark" @click="closeClick"></i></span></div>
                <cube-scroll  ref="scroll2" >
                <div style="margin-top: 10px;font-size: 16px;line-height: 20px;">
                    {{Detail.Title}}
                </div>
                <div style="margin-top: 10px;font-size: 16px;line-height: 20px;">
<pre>
{{Detail.Content}}
</pre>
                </div>
                <!-- <div v-html="Detail.Content" style="margin-top: 10px;font-size: 16px;line-height: 20px;"> -->
<!--                <div style="margin-top: 10px;font-size: 16px;line-height: 20px;">
                    <img :src="config.ImgUrl+'banner/'+Detail.ContentImg" class="record-img" style="width: 100%;"/>
                </div> -->
                </cube-scroll>
            </div>
            <div class="keyboard_block" ></div>
        </div>
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar-reback.vue'
import { apiPromotion } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            showDetail:0,
            checkDetail:null,
            results:[],
            log: {

                pullDownRefreshThreshold: 60,
                pullDownRefreshStop: 40,
                pullDownRefreshTxt: ' ', //'Resfresh Success',

                pullUpLoadThreshold: 0,
                pullUpLoadMoreTxt: '',  //'Load more',
                pullUpLoadNoMoreTxt: '',    //'No more data',
            },
            current_page:1,
            Detail:{
                Title:'',
                ContentImg:'',
            }            
        }
    },
    methods: {
        back()
        {
            this.$emit('switchView');
        },
        getLog(page)
        {
            apiPromotion({
                page: page,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(page==1)
                    {
                        this.results.splice(0,this.results.length);
                    }
                    if(response.data.status)
                    {
                        if(response.data.data == null || response.data.data.length==0)
                        {
                            this.$refs.scroll.forceUpdate(false);
                            return;
                        }
                        this.results=this.results.concat(response.data.data);
                        this.$refs.scroll.refresh();
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    this.$refs.scroll.forceUpdate();
                    return;
                });
        },
        closeClick(){
            this.showDetail=0;
        },
        onItemClick(index)
        {
            // this.checkDetail=this.results[index];
            this.showDetail=1;
            this.Detail.Title = this.results[index].Title
            //this.Detail.ContentImg = this.results[index].ContentImg
            this.Detail.Content = this.results[index].Content
            setTimeout(() => {
                this.$refs.scroll2.refresh()
            }, 500);
            //this.dialog("alert",this.results[index].Title,this.results[index].Content);
        },        
        // onItemClick(index)
        // {
        //     // this.checkDetail=this.results[index];
        //     // this.showDetail=1;
        //     this.dialog("alert",this.results[index].Title,this.results[index].Content);
        // },
        logRefresh(){
            this.current_page=1;
            setTimeout(() => {
                this.getLog(this.current_page);
            }, 1000);
        },      
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        scrollLogOpts() {
            return {
                pullDownRefresh: {
                    threshold: parseInt(this.log.pullDownRefreshThreshold),
                    txt: this.log.pullDownRefreshTxt
                },
                pullUpLoad: false,
            }
        },        
    },
    components: {
      MainPage,
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-index
    .record-top
        display flex;
        padding-bottom: 5px;
        .col
            width 100%;
        .record-img
            width 100%;
    .record-item
        padding: 10px 5px;
        margin-bottom: 8px;
        border-bottom 1px $border-bottom-color solid
</style>
